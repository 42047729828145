import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { parseToDateBR } from "../../../../helpers/commons";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle
} from "../../../../components/block/Block";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import { Row } from "../../../../components/grid/Grid";
import { Button } from "reactstrap";

const PixOutFinish = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { paymentDate, DocumentNumber, Url, Message } = { ...location.state };

  const handleFinish = () => {
    navigate("/conta", { replace: true });
  };

  const handleFile = () => {
    window.open(Url, "_blank");
  };

  return (
    <React.Fragment>
      <Head title="Pagamento Pix" />
      <Content>
        <Block p={4}>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle>{Message}</BlockTitle>
                <BlockDes className="text-soft">
                  {`Seu pagamento foi agendado para ${parseToDateBR(
                      paymentDate
                  )}`}
                </BlockDes>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Row mt={4} spacing={2}>
            <BlockTitle>Protocolo: {DocumentNumber}</BlockTitle>
            <Row>
              <Button variant="outlined" size="l" onClick={handleFile}>
                BAIXAR COMPROVANTE
              </Button>
              <Button variant="contained" size="l" onClick={handleFinish}>
                VOLTAR PARA O INÍCIO
              </Button>
            </Row>
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default PixOutFinish;
