//theme
import {Button, FormGroup, Modal, ModalBody, ModalHeader, Spinner} from "reactstrap";
import Icon from "../../../components/icon/Icon";
import React, { useCallback, useEffect, useState } from "react";
import { Col, OutlinedInput, Row } from "../../../components/Component";
import OutlinedSelect from "../../../components/select/OutlinedSelect";
// form
import { useFormik } from "formik";
import * as Yup from "yup";
// custom
import { _selectedValue, weekdaysName } from "../../../helpers/commons";
import http from "./../../../helpers/http";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { toastOptions } from "../../../helpers/presets";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const UserForm = ({ isOpen, onClosed, userData, onAfterSave }) => {
  const { UserId, OwnerId } = { ...userData };
  const [groupsData, setGroupsData] = useState([]);
  const [pronounsData, setPronounsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormLoading, setFormLoading] = useState(false);

  const {
    values,
    errors,
    isValid,
    setFieldValue,
    handleChange,
    handleSubmit,
    setValues,
    resetForm,
    touched,
  } = useFormik({
    initialValues: {
      GroupId: "",
      FullName: "",
      Login: "",
      AccessDaysAllow: [],
      AccessTimeStart: "",
      AccessTimeEnd: "",
      isActive: false,
    },
    validationSchema: Yup.object({
      GroupId: Yup.number().required("Preencha este campo"),
      FullName: Yup.string().required("Preencha este campo"),
      Login: Yup.string().required("Preencha este campo"),
      AccessDaysAllow: Yup.array().required("Preencha este campo"),
      AccessTimeStart: Yup.string().required("Preencha este campo"),
      AccessTimeEnd: Yup.string().required("Preencha este campo"),
    }),
    onSubmit: async (Values) => {
      try {
        setIsLoading(true);

        const days = [];
        for (let key in Values.AccessDaysAllow) {
          const { value } = Values.AccessDaysAllow[key];
          days.push(value);
        }

        const d = {
          personId: OwnerId,
          groupId: Values.GroupId,
          fullName: Values.FullName,
          login: Values.Login,
          accessDaysAllow: days,
          accessTimeStart: Values.AccessTimeStart,
          accessTimeEnd: Values.AccessTimeEnd,
          isActive: Values.isActive,
        };

        await http.request({
          url: `prestadores/usuario/${UserId || ""}`,
          method: UserId ? "put" : "post",
          data: d,
        });

        toast.success("Salvo com sucesso", { ...toastOptions });

        onAfterSave();
        resetForm();
      } catch (err) {
        const { errors } = err.response.data;
        for (const index in errors) {
          toast.error(errors[index], { ...toastOptions });
        }
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const _fetchUser = useCallback(async () => {
    try {
      await resetForm();
      if (!UserId) return;

      setFormLoading(true);
        setTimeout(() => {
                setFormLoading(false);
              }, 1000);

      const resp = await http.get(`prestadores/usuario/${UserId}`);
      const { data } = resp.data;
      const arrAccessDays = [];
      for (let key in data.AccessDaysAllow) {
        arrAccessDays.push({
          value: data.AccessDaysAllow[key],
          label: weekdaysName(data.AccessDaysAllow[key]),
        });
      }

      await setValues({
        GroupId: data.GroupId,
        FullName: data.FullName,
        Login: data.Login,
        AccessDaysAllow: arrAccessDays,
        AccessTimeStart: data?.Expedient[0],
        AccessTimeEnd: data?.Expedient[1],
        isActive: data.IsActive,
      });
    } catch (err) {
      const { errors } = err.response.data;
      for (const index in errors) {
        toast.error(errors[index], { ...toastOptions });
      }
      console.log(err);
    }
  }, [UserId, resetForm, setValues]);

  const _fetchGrupos = useCallback(async () => {
    try {
      const resp = await http.get(`tipos/grupos-usuarios/6`);
      const { data } = resp.data;
      setGroupsData(resp.status === 200 ? data : []);
    } catch (err) {
      const { errors } = err.response.data;
      for (const index in errors) {
        toast.error(errors[index], { ...toastOptions });
      }
      console.log(err);
    }
  }, []);

  const groupOptions = groupsData.map((item) => ({
    value: parseInt(item.Id, 10),
    label: item.GroupName,
  }));

  const _fetchPronouns = useCallback(async () => {
    try {
      const resp = await http.get(`extra/pronomes`);
      const { data } = resp.data;
      setPronounsData(resp.status === 200 ? data : []);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const pronounOptions = pronounsData.map((item) => ({
    value: item.short,
    label: item.long,
  }));

  const weekDayOptions = weekdaysName().map((item) => ({
    value: item.value,
    label: item.label,
  }));

  useEffect(() => {
    const fn = async () => {
      await _fetchGrupos();
      await _fetchPronouns();
      await _fetchUser(UserId);
    };
    fn().then();
    return () => fn;
  }, [setValues, _fetchGrupos, _fetchPronouns, _fetchUser, UserId]);

  return (
    <Modal isOpen={isOpen} onClosed={onClosed}>
      <ModalHeader
        close={
          <button onClick={onClosed} className="close">
            <Icon name="cross" />
          </button>
        }
      >
        Conta de Usuário
      </ModalHeader>
      <ModalBody>
        {isFormLoading ? (
          <div>
            <Row>
              <Col xl={4}>
                <Skeleton height="50px" />
              </Col>
              <Col xl={8}>
                <Skeleton height="50px" />
              </Col>
            </Row>
            <Skeleton height="50px" />
            <div>
              <Skeleton height="50px" />
            </div>
            <div>
              <Skeleton height="50px" />
            </div>
            <Col xl={12}>
              <Skeleton height="50px" />
            </Col>
            <Row>
              <Col xl={6}>
                <Skeleton height="50px" />
              </Col>
              <Col xl={6}>
                <Skeleton height="50px" />
              </Col>
            </Row>
            <Col xl={4}>
              <Skeleton height="50px" />
            </Col>
          </div>
        ) : (
          <>
            <FormGroup>
              <label className="form-label">Identificação</label>
              <Col>
                <Row>
                  <Col xl={4}>
                    <OutlinedSelect
                        size="xl"
                        label="Tratamento"
                        placeholder=""
                        id="SubjectPronouns"
                        value={_selectedValue(
                            pronounOptions,
                            values?.SubjectPronouns
                        )}
                        onChange={(e) => setFieldValue("SubjectPronouns", e?.value)}
                        options={pronounOptions}
                    />
                  </Col>
                  <Col xl={8}>
                    <OutlinedInput
                        size="xl"
                        label="Nome Completo"
                        id="FullName"
                        type="text"
                        required
                        value={values.FullName}
                        onChange={handleChange}
                        isInvalid={errors.FullName && touched.FullName}
                        error={errors.FullName}
                    />
                  </Col>
                </Row>
              </Col>
            </FormGroup>
            <FormGroup>
              <OutlinedSelect
                size="xl"
                label="Perfil da Conta"
                placeholder=""
                id="GroupId"
                required
                value={_selectedValue(groupOptions, values?.GroupId)}
                onChange={(e) => setFieldValue("GroupId", e?.value)}
                options={groupOptions}
                isInvalid={errors.GroupId && touched.GroupId}
                error={errors.GroupId}
              />
            </FormGroup>
            <FormGroup>
              <label className="form-label">Controle de acesso</label>
              <OutlinedInput
                id="Login"
                size="xl"
                label="Login (Email)"
                type="email"
                required
                value={values.Login}
                onChange={handleChange}
                isInvalid={errors.Login && touched.Login}
                error={errors.Login}
              />
            </FormGroup>
            <FormGroup>
              <OutlinedSelect
                  label="Dias de Acesso"
                  placeholder=""
                  size="xl"
                  isMulti
                  id="AccessDaysAllow"
                  value={values.AccessDaysAllow}
                  options={weekDayOptions}
                  onChange={(e) => {
                    setFieldValue("AccessDaysAllow", e);
                  }}
              />
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xs={6} sm={6}>
                  <OutlinedInput
                      required
                      id="AccessTimeStart"
                      name="AccessTimeStart"
                      label="Início"
                      size={"xl"}
                      type="time"
                      value={values.AccessTimeStart}
                      onChange={handleChange}
                      isInvalid={
                          errors.AccessTimeStart && touched.AccessTimeStart
                      }
                      error={errors.AccessTimeStart}
                  />
                </Col>
                <Col xs={6} sm={6}>
                  <OutlinedInput
                      required
                      id="AccessTimeEnd"
                      name="AccessTimeEnd"
                      label="Fim"
                      size={"xl"}
                      type="time"
                      value={values.AccessTimeEnd}
                      onChange={handleChange}
                      isInvalid={errors.AccessTimeEnd && touched.AccessTimeEnd}
                      error={errors.AccessTimeEnd}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  id="isActive"
                  className="custom-control-input"
                  checked={values.isActive}
                  onChange={handleChange}
                />
                <label className="custom-control-label" htmlFor="isActive">
                  Conta ativa
                </label>
              </div>
            </FormGroup>
            <Button
              onClick={handleSubmit}
              size="l"
              color="primary"
              disabled={!isValid}
            >
              {isLoading ? <Spinner size="sm" color="light" /> : "SALVAR"}
            </Button>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

UserForm.propType = {
  onAfterSave: PropTypes.func,
  userData: PropTypes.object,
};

export default UserForm;
