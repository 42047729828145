import axios from 'axios';

const http = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URI, headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'Authorization': 'Basic ' + process.env.REACT_APP_BASIC,
  },
});

// Interceptador de erro de requisição
http.interceptors.request.use(config => {
  return config;
}, error => {
  return Promise.reject(error.request);
});

export default http;
