/**
 * @author: Alexandre G R Alves
 */

import React, {useEffect, useState} from "react";
import MaskedInput from "react-text-mask";

const OutlinedInput = React.forwardRef((props, inputRef) => {
    const {
        mask,
        label,
        size,
        isInvalid,
        error,
        iconRight,
        iconLeft,
        ...rest
    } = {...props}


    const [focus, setFocus] = useState(false);

    useEffect(() => {
        setFocus('undefined' !== typeof rest.value && rest.value !== '');
    }, [rest.value])

    const _handleFocus = (e) => {
        setFocus(true);
        if (rest.onFocus) rest.onFocus(e);
    }

    const _handleBlur = (e) => {
        setFocus(e.target.value);
        if (rest.onBlur) rest.onBlur(e);
    }

    const commonProps = {
        ref: inputRef,
        className: `form-control-outlined form-control ${size ? `form-control-${size}` : ""}`,
        onFocus: _handleFocus,
        onBlur: _handleBlur
    }

    return (
        <div className={`form-control-wrap ${focus ? "focused" : ""}`}>
            {iconRight && (
                <div className={`form-icon form-icon-right ${size}`}>
                    {iconRight}
                </div>
            )}

            {mask ? (<MaskedInput {...rest} {...commonProps} mask={mask}/>) : (<input {...rest} {...commonProps}/>)}

            {iconLeft && (
                <div className={`form-icon form-icon-left ${size}`}>
                    {iconLeft}
                </div>
            )}

            <label className="form-label-outlined z-3" htmlFor={props.id}>
                {label}{props.required ? <span className={'text-danger'}>*</span> : ''}
            </label>
            {isInvalid && error && <span className="invalid">{error}</span>}
        </div>
    );
}, []);

export default OutlinedInput;
