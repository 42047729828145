import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {AppContextProvider} from './context/AppContext';
import App from './App';

import "./assets/scss/dashlite.scss";
import "./assets/scss/style-email.scss";

const container = document.getElementById('app');
const root = createRoot(container);
const context = (
    <BrowserRouter>
        <AppContextProvider>
          <App/>
        </AppContextProvider>
    </BrowserRouter>
);

root.render(context);

