import React from "react";
import { useAuth } from "../../hooks/useAuth";
import { Card, CardBody, CardGroup, Button } from "reactstrap";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../components/block/Block";
import { Row, Col } from "../../components/grid/Grid";
import { MdOutlineKey } from "react-icons/md";
import { GrTransaction } from "react-icons/gr";
import { FaPix } from "react-icons/fa6";

const Bank = () => {
  const { user } = useAuth();
  const { BankAccount, BankAccountDigit, BankBranch } = user;

  return (
    <React.Fragment>
      <Head title="Conta Digital" />
      <Content>
        <BlockHead>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag={"h3"}>
                Conta Digital
              </BlockTitle>
              <BlockDes className={"text-soft"}>
                <p>Acesse sua conta digital</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <div className="toggle-expand-content">
                  <ul className="nk-block-tools">
                    <li className="nk-block-tools-opt">
                      <Col>
                        <p>Agência: {BankBranch}</p>
                        <p>
                          Conta: {BankAccount}-{BankAccountDigit}
                        </p>
                      </Col>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Row>
          <CardGroup className="g-3 mt-2">
            <Col lg="4">
              <Card className="card-bordered">
                <CardBody>
                  <GrTransaction color="#442B7F" size={28} />
                  <div className="mt-1">
                    <h5>Saldo/Extrato</h5>
                    <p color="gray">
                      Visualize saldo e movimentações de conta.
                    </p>
                  </div>
                  <Button
                    className="btn-dim mt-1 text-primary"
                    color="transparent"
                    href="/conta/extrato"
                  >
                    ACESSAR
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-bordered">
                <CardBody>
                  <MdOutlineKey size={28} color="#442B7F" />
                  <div className="mt-1">
                    <h5>Chave Pix</h5>
                    <p color="gray">
                      Administrar chave Pix vinculadas à sua conta.
                    </p>
                  </div>
                  <Button
                    className="btn-dim text-primary"
                    color="transparent"
                    href="/conta/pix/chave"
                  >
                    ACESSAR
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-bordered ">
                <CardBody>
                  <FaPix size={28} color="#442B7F" />
                  <div className="mt-1">
                    <h5>Pagar com Pix</h5>
                    <p color="gray">Realizar pagamentos utilizando chave Pix</p>
                  </div>
                  <Button
                    className="btn-dim text-primary"
                    color="transparent"
                    href="/conta/pix/pagamento"
                  >
                    ACESSAR
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </CardGroup>
        </Row>
      </Content>
    </React.Fragment>
  );
};

export default Bank;
