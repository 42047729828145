import React, { useCallback, useEffect, useState } from "react";
import http from "../../../helpers/http";
import {
  formatNumber,
  getLastDayOfMonth,
  listMonths,
  parseToDateBR,
  rangeNumber,
} from "../../../helpers/commons";
import { useAuth } from "../../../hooks/useAuth";
import Content from "../../../layout/content/Content";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Block,
} from "../../../components/block/Block";
import Head from "../../../layout/head/Head";
import { Row, Col } from "../../../components/grid/Grid";
import { Button, Card, Spinner } from "reactstrap";
import { FaReceipt } from "react-icons/fa";
import OutlinedSelect from "../../../components/select/OutlinedSelect";
import Icon from "../../../components/icon/Icon";

const date = new Date();
const currDay = date.getDate();
const currYear = date.getFullYear();
const currMonth = date.getMonth() + 1;

const BancoExtrato = () => {
  const [isShowBalance, setShowBalance] = useState(false);
  const { user } = useAuth();
  const { PartnerTaxNumber, BankAccountKey } = user;
  const [isLoading, setLoading] = useState(true);
  const [dataEntry, setDataEntry] = useState([]);
  const [balance, setBalance] = useState([]);
  const [filterDay, setFilterDay] = useState(currDay.toString());
  const [filterMonth, setFilterMonth] = useState(currMonth.toString());
  const [filterYear] = useState(currYear.toString());

  const fetchEntry = useCallback(async () => {
    try {
      setLoading(true);
      const startDate = `${filterDay}/${filterMonth}/${filterYear}`;
      const endDate = `${getLastDayOfMonth(
        filterMonth,
        filterYear
      )}/${filterMonth}/${filterYear}`;
      const taxNumber = PartnerTaxNumber.replace(/\D/g, "");
      const res = await http.get(`banco/conta/${taxNumber}/extrato`, {
        params: {
          accountKey: BankAccountKey,
          onlyBalance: false,
          startDate,
          endDate,
        },
      });
      const { data } = res.data;
      setDataEntry(data);

      const res2 = await http.get(`banco/conta/${taxNumber}/extrato`, {
        params: {
          accountKey: BankAccountKey,
          onlyBalance: true,
        },
      });
      setBalance(res2.data.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [BankAccountKey, PartnerTaxNumber, filterDay, filterMonth, filterYear]);

  const dayOptions = rangeNumber(
    1,
    getLastDayOfMonth(filterMonth, filterYear)
  ).map((k) => ({
    value: k.toString(),
    label: k.toString(),
  }));

  const monthListOptions = listMonths.map((row) => ({
    value: row.value,
    label: row.label,
  }));

  useEffect(() => {
    fetchEntry().then();
  }, [fetchEntry]);

  const RenderEntry = useCallback(({ data }) => {
    return (
      <Block>
        {data &&
          data.map((item, index) => {
            let cl = item.EntryValue > 0 ? "green" : "red";
            return (
              <React.Fragment key={index.toString()}>
                <Row>
                  <Col>
                    <h6>
                      {parseToDateBR(
                        item.EntryDate,
                        false,
                        true,
                        true
                      ).toLowerCase()}
                    </h6>
                  </Col>
                  <Col flexGrow={1}>
                    <h6>{item.Details}</h6>
                    <h6>R$ {formatNumber(item.EntryValue)}</h6>
                    {item.DocumentNumber && <h6>Doc {item.DocumentNumber}</h6>}
                  </Col>
                  {item.ReceiptUrl && (
                    <Button
                      className="btn-round btn-icon"
                      color="primary"
                      size="lg"
                      href={item.ReceiptUrl}
                      target="_blank"
                    >
                      <FaReceipt />
                    </Button>
                  )}
                </Row>
                <li className="divider"></li>
              </React.Fragment>
            );
          })}
      </Block>
    );
  }, []);

  return (
    <React.Fragment>
      <Head title="Extrato" />
      <Content p={4}>
        <BlockHead>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag={"h3"}>
                Extrato
              </BlockTitle>
              <BlockDes className={"text-soft"}>
                <p>
                  Aqui você pode visualizar seu extrato detalhado com todas as
                  transações realizadas
                </p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Row>
          <Row>
            <Col>
              <Card>
                <span color="primary">Saldo atual</span>
                <Col alignItems="center">
                  <h4 color="primary">
                    R$ {isShowBalance ? formatNumber(balance) : "●●●●●"}
                  </h4>
                  <Button
                    color="default"
                    onClick={() => setShowBalance(!isShowBalance)}
                  >
                    {isShowBalance ? (
                      <Icon name="eye" />
                    ) : (
                      <Icon name="eye-off" />
                    )}
                  </Button>
                </Col>
              </Card>
            </Col>
            <Card>
              <Row>
                <h5>Filtro</h5>
                <Col>
                  <OutlinedSelect
                    size="xl"
                    label="A partir do dia"
                    placeholder=""
                    onChange={(e) => setFilterDay(e?.value)}
                    options={dayOptions}
                  />

                  <OutlinedSelect
                    size="xl"
                    label="Mês"
                    placeholder=""
                    onChange={(e) => setFilterMonth(e?.value)}
                    options={monthListOptions}
                  />
                </Col>
              </Row>
            </Card>
          </Row>
          <Row>
            {isLoading && (
              <>
                <Row className="text-center mt-4">
                  <Col>
                    <Spinner color="primary" />
                  </Col>
                </Row>
                <RenderEntry data={dataEntry} />
              </>
            )}
          </Row>
        </Row>
      </Content>
    </React.Fragment>
  );
};

export default BancoExtrato;
