import React, { useState, useCallback, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import masks from "../../../../helpers/masks";
import { pixKeysTypes } from "../../../../helpers/types";
import { useNavigate } from "react-router-dom";
import http from "../../../../helpers/http";
import { useAuth } from "../../../../hooks/useAuth";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../../../components/block/Block";
import { Col, Row } from "../../../../components/grid/Grid";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import { Button, Spinner } from "reactstrap";
import OutlinedSelect from "../../../../components/select/OutlinedSelect";
import OutlinedInput from "../../../../components/input/outlined-input/OutlinedInput";
import {toast} from "react-toastify";
import {toastOptions} from "../../../../helpers/presets";

const PixOutMain = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { PartnerTaxNumber, BankAccountKey } = user;
  const [AccountBalance, setAccountBalance] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [mask, setMask] = useState([]);

  const {
    values,
    errors,
    isValid,
    handleChange,
    handleSubmit,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: {
      pixKeyType: "0",
      pixKey: "",
    },
    validationSchema: Yup.object().shape({
      pixKeyType: Yup.string().required("Selecione o tipo da chave"),
      pixKey: Yup.string().required("Informe a chave pix"),
    }),
    onSubmit: async (v) => {
      try {
        setLoading(true);
        const resp = await http.get("banco/pix/chave/consultar", {
          params: {
            taxNumber: PartnerTaxNumber,
            ...v,
          },
        });
        const { data } = resp.data;
        navigate("/conta/pix/pagamento/valor", {
          state: {
            ...data,
            AccountBalance,
            FromTaxNumber: PartnerTaxNumber,
            FromAccountKey: BankAccountKey,
          },
        });
      } catch (e) {
        toast.error(e.response.data.errors[0],{...toastOptions});
      } finally {
        setLoading(false);
      }
    },
  });

  const getBalance = useCallback(async () => {
    try {
      const res = await http.get(
        `banco/conta/${PartnerTaxNumber.replace(/\D/g, "")}/extrato`,
        {
          params: {
            accountKey: BankAccountKey,
            onlyBalance: true,
          },
        }
      );
      const { data } = res.data;
      setAccountBalance(data);
    } catch (e) {
      console.log(e);
    }
  }, [PartnerTaxNumber, BankAccountKey]);

  const pixKeysOptions = pixKeysTypes.map((row) => ({
    value: row.value,
    label: row.label,
  }));

  useEffect(() => {
    getBalance().then();
  }, [getBalance]);

  useEffect(() => {
    switch (parseInt(values.pixKeyType)) {
      case 0:
        setMask(masks.CPF);
        break;
      case 1:
        setMask(masks.CNPJ);
        break;
      case 3:
        setMask(masks.CELPHONE);
        break;
      default:
        break;
    }
  }, [values.pixKeyType]);

  return (
    <React.Fragment>
      <Head title="Pagamento Pix" />
      <Content>
        <BlockHead>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag={"h3"}>
                Quem você está pagando?
              </BlockTitle>
              <BlockDes className={"text-soft"}>
                <p>Informe a chave Pix</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Row>
          <Col className="mb-5 g-4" lg={6}>
            <OutlinedSelect
              size="xl"
              id="pixKeyType"
              label="Tipo de chave"
              placeholder=""
              value={values.pixKeyType}
              options={pixKeysOptions}
              onChange={(e) => {
                setFieldValue("pixKeyType", e?.value);
              }}
              error={errors.pixKeyType && touched.pixKeyType}
            />
            <Col className={"g-3"}>
              {parseInt(values.pixKeyType) === 2 ||
              parseInt(values.pixKeyType) === 4 ? (
                <OutlinedInput
                  id="pixKey"
                  type="text"
                  mask={mask}
                  label="Chave Pix"
                  onChange={handleChange}
                  value={values.pixKey}
                  error={errors.pixKey && touched.pixKey}
                />
              ) : (
                <OutlinedInput
                  id="pixKey"
                  type="text"
                  mask={mask}
                  label="Chave Pix"
                  onChange={handleChange}
                  value={values.pixKey}
                  error={errors.pixKey && touched.pixKey}
                />
              )}
            </Col>
            <Button
              disabled={!isValid}
              loading={isLoading}
              onClick={handleSubmit}
              color="primary"
            >
              {isLoading ? <Spinner size="sm" color="light" /> : "CONTINUAR"}
            </Button>
          </Col>
        </Row>
      </Content>
    </React.Fragment>
  );
};

export default PixOutMain;
