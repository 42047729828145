import React, { useCallback, useEffect, useState } from "react";
import http from "../../helpers/http";
import { parseToDateBR } from "../../helpers/commons";
import { statusVoucher, statusVoucherColor } from "../../helpers/types";
import { useAuth } from "../../hooks/useAuth";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  DataTable,
  DataTableBody,
  DataTableItem,
  DataTableRow,
  DataTableHead,
} from "../../components/Component";
import { Col, Row } from "../../components/grid/Grid";
import OutlinedInput from "../../components/input/outlined-input/OutlinedInput";
import { Spinner, Badge, Button } from "reactstrap";
import Icon from "../../components/icon/Icon";
import VoucherDetails from "./components/VoucherDetails";

const currentDate = new Date().toISOString().split("T")[0];

const Dashboard = () => {
  const [dataAppointment, setDataAppoint] = useState([]);
  const [scheduleAtDate, setScheduleAtDate] = useState(currentDate);
  const [isLoading, setLoading] = useState(false);
  const [voucherDetails, setVoucherDetails] = useState(false);
  const [voucherData, setVoucherData] = useState();
  const { user } = useAuth();
  const { OwnerId } = { ...user };

  const _fetchAppointment = useCallback(async () => {
    try {
      setLoading(true);
      const params = {
        partnerId: OwnerId,
        scheduledAt: scheduleAtDate,
      };

      const res = await http.get("saude/guias", {
        params,
      });

      const { data } = { ...res.data };
      setDataAppoint(res.status === 200 ? data : []);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [scheduleAtDate, OwnerId]);

  useEffect(() => {
    _fetchAppointment().then();
  }, [_fetchAppointment, scheduleAtDate]);

  const getVoucherDetails = (row) => {
    if (row) {
      setVoucherData({ VoucherId: row.Id });
    }
    setVoucherDetails(true);
  };

  return (
    <React.Fragment>
      <Head title="Dashboard" />
      <Content>
        <BlockHead>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag={"h3"}>
                Dashboard
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>Relação de atendimentos agendados</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Row>
          <Col lg={4}>
            <OutlinedInput
              id="scheduleAtDate"
              type="date"
              label="Data do atendimento"
              size="xl"
              onChange={(e) => {
                setScheduleAtDate(e.target.value);
              }}
              value={scheduleAtDate}
            />
          </Col>
        </Row>
        <Block>
          <Row className="mt-4">
            <Col>
              <DataTable className="card-stretch">
                <DataTableBody>
                  <DataTableHead>
                    <DataTableRow size="sm">
                      <span className="sub-text fw-medium">Horário</span>
                    </DataTableRow>
                    <DataTableRow size="lead">
                      <span className="sub-text fw-medium">Paciente</span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span className="sub-text fw-medium">Profissional</span>
                    </DataTableRow>
                    <DataTableRow size="sm" className="text-center">
                      <span className="sub-text fw-medium">Situação</span>
                    </DataTableRow>
                    <DataTableRow size="sm" className="text-center">
                      <span className="sub-text fw-medium">Detalhes</span>
                    </DataTableRow>
                  </DataTableHead>
                  {isLoading ? (
                    <React.Fragment>
                      <DataTableItem>
                        <DataTableRow>
                          <Spinner color="primary" />
                        </DataTableRow>
                      </DataTableItem>
                    </React.Fragment>
                  ) : dataAppointment.length === 0 ? (
                    <React.Fragment>
                      <DataTableItem>
                        <DataTableRow className="text-center">
                          <h6>Não há atendimentos para esta data.</h6>
                        </DataTableRow>
                      </DataTableItem>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {dataAppointment.map((row, index) => (
                        <DataTableItem key={index}>
                          <DataTableRow size="sm">
                            <span>
                              {parseToDateBR(row.ServiceScheduledAt, true)}
                            </span>
                          </DataTableRow>
                          <DataTableRow size="lg">
                            <span className="tb-lead" color="primary">
                              {row.PatientName}
                            </span>
                          </DataTableRow>
                          <DataTableRow size="lg">
                            <span>{row.DoctorName}</span>
                          </DataTableRow>
                          <DataTableRow size="sm" className="text-center">
                            <Badge
                              color={statusVoucherColor[row.VoucherStatus]}
                            >
                              {statusVoucher[row.VoucherStatus]}
                            </Badge>
                          </DataTableRow>
                          <DataTableRow size="sm" className="text-center">
                            <Button
                              className="btn-round btn-icon"
                              color="transparent"
                              size="sm"
                              onClick={() => getVoucherDetails(row)}
                            >
                              <Icon name="file-plus" />
                            </Button>
                          </DataTableRow>
                        </DataTableItem>
                      ))}
                    </React.Fragment>
                  )}
                </DataTableBody>
              </DataTable>
            </Col>
          </Row>
        </Block>
        {voucherData && (
          <VoucherDetails
            isOpen={voucherDetails}
            onClosed={() => setVoucherDetails(false)}
            voucherData={voucherData}
          />
        )}
      </Content>
    </React.Fragment>
  );
};

export default Dashboard;
