import React from "react";
import { Link } from "react-router-dom";

import Logo from "../../../images/logo2x.png";
import LogoDark from "../../../images/logo2x.png";
import {Block, BlockContent} from "../../../components/Component";
import {Button} from "reactstrap";

const Error404Classic = () => {
	return (
		<>
			<Block className="nk-block-middle wide-xs mx-auto">
				<BlockContent className="nk-error-ld text-center">
					<div className="brand-logo pb-4 text-center">
						<Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
							<img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo"/>
							<img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark"/>
						</Link>
					</div>
					<h1 className="nk-error-head">404</h1>
					<h3 className="nk-error-title">Oops! Why you’re here?</h3>
					<p className="nk-error-text">
						We are very sorry for inconvenience. It looks like you’re try to access a page that either has been deleted
						or never existed.
					</p>
					<Link to={`${process.env.PUBLIC_URL}/`}>
						<Button color="primary" size="lg" className="mt-2">
							Back To Home
						</Button>
					</Link>
				</BlockContent>
			</Block>
		</>
	);
};
export default Error404Classic;
