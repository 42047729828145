import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  formatNumber,
  parseToISODate,
  secretTaxNumber,
} from "../../../../helpers/commons";
import { useLocation, useNavigate } from "react-router-dom";
import http from "./../../../../helpers/http";
import moment from "moment";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../../../components/block/Block";
import { Row } from "../../../../components/grid/Grid";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import { Button, OutlinedInput } from "../../../../components/Component";
import { Spinner } from "reactstrap";
import {toast} from "react-toastify";
import {toastOptions} from "../../../../helpers/presets";

const today = new Date();

const PixOutResume = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [isLoading, setLoading] = useState(false);

  const {
    values,
    errors,
    isValid,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
  } = useFormik({
    initialValues: {
      paymentDate: today.toISOString().split("T")[0],
      description: "",
      customerMessage: "",
    },
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
      paymentDate: Yup.string()
        .test("minDate", "Data inválida", (value) => {
          const now = moment(new Date(), "YYYY-MM-DD");
          const val = moment(parseToISODate(value));
          const diff = now.diff(moment(val), "days");
          return diff <= 0;
        })
        .required("Informe a data para transferência"),
      description: Yup.string().required("Informe uma observação"),
    }),
    onSubmit: async (v) => {
      try {
        setLoading(true);

        const {
          FromTaxNumber,
          FromAccountKey,
          PixKeyValue,
          PixKeyType,
          ReceiverBank,
          ReceiverBankAccount,
          ReceiverBankAccountDigit,
          ReceiverAccountType,
          ReceiverBankBranch,
          ReceiverName,
          ReceiverTaxNumber,
          SearchProtocol,
          Amount,
        } = { ...state };

        const d = {
          fromTaxNumber: FromTaxNumber,
          fromAccountKey: FromAccountKey,
          pixKey: PixKeyValue,
          pixKeyType: PixKeyType,
          toBank: ReceiverBank,
          toBankAccount: ReceiverBankAccount,
          toBankAccountDigit: ReceiverBankAccountDigit,
          toBankAccountType: ReceiverAccountType,
          toBankBranch: ReceiverBankBranch,
          toName: ReceiverName,
          toTaxNumber: ReceiverTaxNumber,
          searchProtocol: SearchProtocol,
          value: Amount,
          paymentDate: v.paymentDate,
          description: v.description,
          customerMessage: v.customerMessage,
        };

        const resp = await http.post("banco/pix/pagar", d);
        const { data } = resp.data;

        navigate("/conta/pix/pagamento/concluido", {
          replace: true,
          state: { ...v, ...data },
        });
      } catch (e) {
        toast.error(e.response.data.errors[0],{...toastOptions});
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <React.Fragment>
      <Head title="Pagamento Pix" />
      <Content>
        <BlockHead>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag={"h3"}>
                Pagando R$ {formatNumber(state.Amount)}
              </BlockTitle>
              <BlockDes className={"text-soft"}>
                <p>{`Para ${state.ReceiverName}`}</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Row spacing={1} mt={4}>
          <Row>
            <span>CPF/CNPJ</span>
            <span>{secretTaxNumber(state.ReceiverTaxNumber)}</span>
          </Row>
          <Row>
            <span>Instituição</span>
            <span>{state.BankInfo.Name}</span>
          </Row>
          <Row>
            <span>Agência</span>
            <span>{state.ReceiverBankBranch}</span>
          </Row>
          <Row>
            <span>Conta</span>
            <span>
              {state.ReceiverBankAccount}-{state.ReceiverBankAccountDigit}
            </span>
          </Row>
          <div className="nk-divider divider sm"></div>
          <span>Detalhes da transferência</span>
          <Row
            space={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <span>Agendar pagamento para</span>
            <OutlinedInput
              id="paymentDate"
              type="date"
              variant="outlined"
              label="Data"
              value={values.paymentDate}
              error={errors.paymentDate && touched.paymentDate}
              helperText={errors.paymentDate}
              onChange={handleChange("paymentDate")}
              onBlur={handleBlur}
            />
          </Row>
          <OutlinedInput
            id="description"
            variant="outlined"
            label="Observação"
            value={values.description}
            error={errors.description && touched.description}
            helperText={errors.description}
            onChange={handleChange("description")}
            onBlur={handleBlur}
          />
          <OutlinedInput
            id="customerMessage"
            variant="outlined"
            label="Mensagem para o recebedor"
            value={values.customerMessage}
            error={errors.customerMessage && touched.customerMessage}
            helperText={errors.customerMessage}
            onChange={handleChange("customerMessage")}
            onBlur={handleBlur}
          />
          <div className="nk-divider divider sm"></div>
          <Button
            size="l"
            disabled={!isValid}
            loading={isLoading}
            onClick={handleSubmit}
            color="primary"
          >
            {isLoading ? <Spinner size="sm" color="light" /> : "CONTINUAR"}
          </Button>
        </Row>
      </Content>
    </React.Fragment>
  );
};

export default PixOutResume;
