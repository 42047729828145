import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { toastOptions } from "../../helpers/presets";
import http from "../../helpers/http";
import { formatNumber, parseToDateBR } from "../../helpers/commons";
import { CPF } from "../../helpers/masks";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../components/block/Block";
import { _selectedValue } from "../../helpers/commons";
import OutlinedSelect from "../../components/select/OutlinedSelect";
import OutlinedInput from "../../components/input/outlined-input/OutlinedInput";
import {
  Card,
  CardSubtitle,
  CardText,
  CardTitle,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { Col, Row } from "../../components/grid/Grid";
import {
  Icon,
  DataTable,
  PreviewAltCard,
  DataTableBody,
  DataTableItem,
  DataTableRow,
  Button,
  DataTableHead,
} from "../../components/Component";


const Index = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { OwnerId } = user;
  const [services, setServices] = useState([]);
  const [totalServices, setTotalServices] = useState(0);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [patientData, setPatientData] = useState({});
  const [doctorData, setDoctorData] = useState([]);
  const [addressData, setAddressData] = useState([]);
  const [specialtyData, setSpecialtyData] = useState([]);
  const [procedureData, setProcedureData] = useState([]);
  const [voucherData, setVoucherData] = useState({});
  const [isShowConfirm, setShowConfirm] = useState(false);
  const [isShowConfirmProcedure, setShowConfirmProcedure] = useState(false);
  const [isFormChk, setIsFormChk] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState("");

  /**
   * Configurações do Formik
   */
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      partnerId: OwnerId,
      healthInsurance: "",
      taxNumber: "",
      partnerAddress: { id: null, label: null },
      doctorUser: { id: null, label: null },
      specialty: { id: null, label: null },
      procedure: { id: null, label: null, price: null },
      scheduleAtDate: "",
      scheduleAtTime: "",
      comments: "",
    },
    validationSchema: Yup.object({
      taxNumber: Yup.string().required("Preencha este campo"),
      /* partnerAddress: Yup.string().required("Preencha este campo"), /!*VERIFICAR*!/
                  scheduleAtDate: Yup.string().required("Preencha este campo"),
                  scheduleAtTime: Yup.string().required("Preencha este campo"),*/
    }),
    onSubmit: async (values) => {
      try {
        //envia specialtyId e procedureId p/ array service
        const service = [];
        for (let k in services) {
          service.push({
            specialtyId: services[k].specialty.value,
            procedureId: services[k].procedure.value,
          });
        }
        const postData = {
          taxNumber: values.taxNumber,
          partnerId: OwnerId,
          partnerAddressId: values.partnerAddressId,
          doctorUserId: values.doctorUser,
          scheduleAt: `${values.scheduleAtDate} ${values.scheduleAtTime}`,
          services: service,
          comments: values.comments,
        };
        console.log(postData);

        await http.post("saude/guia", postData);

        toast.success("Guia gerada com sucesso!", { ...toastOptions });
        // Limpa o formulário
        _clearServices();
        navigate("/dashboard", { replace: true });

      } catch (err) {
        for (const index in errors) {
          toast.error(errors[index], { ...toastOptions });
        }
        console.log(err);
      } finally {
        // fecha o dialogo
        setShowConfirm(false);
      }
    },
  });

  /**
   * Buscar dados do paciente
   * @returns {Promise<void>}
   * @private
   */
  const _getPatient = async () => {
    if (isSearching) return;
    setIsSearching(true);
    try {
      const res = await http.get(
        `assinante/${values.taxNumber.replace(/\D/g, "")}`
      );
      const { data } = res.data;
      await setFieldValue("taxNumber", data.TaxNumber);
      setPatientData(data);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.errors[0], { ...toastOptions });
      }
      _resetForm();
    } finally {
      setIsSearching(false);
    }
  };

  /**
   * Carrega as unidades de atendimento
   * @type {(function(): Promise<void>)|*}
   * @private
   */
  const _getAddress = useCallback(async () => {
    try {
      const res = await http.get(`/endereco/${OwnerId}`);
      const { data } = res.data;
      setAddressData(data);
    } catch (err) {
      console.log(err.response ? err.response.data.error : err);
    }
  }, [OwnerId]);

  const addressDataOptions = addressData.map((item) => ({
    label: item.Description,
    value: item.Id,
  }));

  /**
   * Carregar a lista de médicos
   * @type {(function(): Promise<void>)|*}
   * @private
   */
  const _getDoctors = useCallback(async () => {
    try {
      const res = await http.get(`prestadores/usuarios`, {
        params: {
          ownerId: OwnerId,
          groupId: 2,
        },
      });
      const { data } = res.data;
      setDoctorData(data);
    } catch (err) {
      console.log(err.response ? err.response.data.error : err);
    }
  }, [OwnerId]);

  const doctorsOptions = doctorData.map((row) => ({
    label: row.FullName,
    value: row.Id,
  }));

  /**
   * Carrega a lista de especialidades
   * @type {(function(): Promise<void>)|*}
   * @private
   */
  const _getSpecialty = useCallback(async () => {
    try {
      const res = await http.get(`saude/servicos/especialidades`, {
        params: {
          partnerId: OwnerId,
        },
      });
      const { data } = res.data;
      setSpecialtyData(data);
    } catch (err) {
      console.log(err.response ? err.response.data.error : err);
    }
  }, [OwnerId]);

  const specialtyOptions = specialtyData.map((item) => ({
    label: item.Specialty,
    value: item.SpecialtyId,
  }));

  /**
   * carrega a lista de procedimentos com base na especialidade. selecionada
   * @param specialtyId
   * @returns {Promise<void>}
   * @private
   */
  const _getProcedure = async (specialtyId) => {
    if (!specialtyId) {
      setVoucherData({ ...voucherData, procedureId: "" });
      return;
    }
    try {
      const res = await http.get(
        `saude/servicos/especialidade/${specialtyId}/procedimentos`,
        {
          params: {
            partnerId: OwnerId,
          },
        }
      );
      const { data } = res.data;
      setProcedureData(data?.Procedures);
    } catch (err) {
      console.log(err.response ? err.response.data.error : err);
    }
  };

  const procedureOptions = procedureData.map((item) => ({
    label: item.Procedure,
    value: item.ProcedureId,
    price: item.FullPrice,
  }));
  /**
   * Limpa o formulário
   * @type {(function(): void)|*}
   * @private
   */
  const _resetForm = useCallback(() => {
    setFieldValue("specialty", "");
    setFieldValue("procedure", "");
    setVoucherData({});
    setPatientData({});
  }, [setFieldValue]);

  /**
   * Adiciona serviços à lista
   * @param item
   * @private
   */
  const _addService = (item) => {
    setTotalServices(totalServices + parseFloat(item.procedure.price));
    setServices([...services, item]);
  };

  /**
   * Remove procedimentos da lista
   * @param index
   * @private
   */
  const _remService = (index) => {
    let newSrv = services;
    setTotalServices(totalServices - newSrv[index].procedure.price);
    newSrv.splice(index, 1);
    setServices([...newSrv]);
  };

  /**
   * Limpa a lista de procedimentos
   * @private
   */
  const _clearServices = () => {
    setServices([]);
    setTotalServices(0);
  };

  /**
   * MODAL PARA LIMPAR CAMPOS CASO MUDE MÉDICO---------------------------------------------------------------
   * @private
   */
  const _checkDoctorInput = () => {
    setFieldValue("doctorUser", {});
    setShowConfirmProcedure(false);
    _clearServices();
    _resetForm();
  };

  /**
   * Adiciona itens na lista de procedimentos
   * @private
   */
  const _addProcedure = () => {
    setSelectedDoc(values.doctorUser);
    // adiciona os servicos
    const s = _selectedValue(specialtyOptions, values.specialty);
    const p = _selectedValue(procedureOptions, values.procedure);

    _addService({
      specialty: s[0],
      procedure: p[0],
    });

    // limpa o formulário e desabilita o botão
    setFieldValue("specialty", {});
    setFieldValue("procedure", {});
    setIsFormChk(false);
  };

  /**
   * Executa os métodos de carregamento automático de dados
   */
  useEffect(() => {
    const fn = async () => {
      await _getAddress();
      await _getDoctors();
      await _getSpecialty();
    };
    fn().then();
    return () => fn;
  }, [_getAddress, _getDoctors, _getSpecialty]);

  /**
   * Verifica se a lista de procedimentos esta vazia
   */
  useEffect(() => {
    setIsEmpty(!services.length > 0);
  }, [services]);

  useEffect(() => {
    if (values.taxNumber.replace(/\D/g, "").length >= 11) {
      _getPatient().then();
    }
  }, [values.taxNumber]);

  useEffect(() => {
    if (services.length >= 1 && values.doctorUser !== selectedDoc) {
      setShowConfirmProcedure(true);
    }
  }, [values.doctorUser, services, selectedDoc]);

  return (
    <React.Fragment>
      <Head title="Agendamentos" />
      <Content>
        <BlockHead>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag={"h3"}>
                Agendamentos
              </BlockTitle>
              <BlockDes className={"text-soft"}>
                <p>Agende e gere guias de atendimento em nome do paciente</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <h6 className="mb-4">Dados do agendamento</h6>
        <Row>
          <Col className="mb-4">
            <OutlinedInput
                id="taxNumber"
                name="taxNumber"
                mask={CPF}
                label="Informe o CPF do paciente"
                size="xl"
                value={values.taxNumber}
                onChange={handleChange}
                iconRight={
                  <Button color="transparent" className={"btn-icon me-2"}>
                    <Icon name={"search"} />
                  </Button>
                }
                isInvalid={errors.taxNumber && touched.taxNumber}
                error={errors.taxNumber}
                onBlur={handleBlur}
            />
            <Card className="mt-2">
              {isSearching ? (
                  <Row className="text-center mt-4">
                    <Col>
                      <Spinner color="primary" />
                    </Col>
                  </Row>
              ) : (
                  <Row>
                    <Col>
                      {!patientData.FullName ? (
                          <PreviewAltCard>
                            <Col>
                              <h6 className="text-primary">
                                Nenhum paciente foi informado!
                              </h6>
                              <p>
                                Utilize o campo acima para localizar um paciente
                                através do número de seu CPF.
                              </p>
                            </Col>
                          </PreviewAltCard>
                      ) : (
                          <DataTable className="card-stretch">
                            <DataTableBody>
                              <DataTableHead>
                                <DataTableRow>
                                  <span>Paciente</span>
                                </DataTableRow>
                                <DataTableRow>
                                  <span>Matrícula</span>
                                </DataTableRow>
                                <DataTableRow>
                                  <span>Data de Nascimento</span>
                                </DataTableRow>
                                <DataTableRow>
                                  <span>Contato</span>
                                </DataTableRow>
                              </DataTableHead>
                              <DataTableItem>
                                <DataTableRow>
                            <span className="tb-lead" color="primary">
                              {patientData?.FullName}
                            </span>
                                </DataTableRow>
                                <DataTableRow>
                            <span>
                              {patientData?.HealthInsuranceNumber || ""}
                            </span>
                                </DataTableRow>
                                <DataTableRow>
                            <span>
                              {parseToDateBR(patientData?.BirthDate || "")}
                            </span>
                                </DataTableRow>
                                <DataTableRow>
                                  <span>{patientData?.Contacts?.Phone || ""}</span>
                                </DataTableRow>
                              </DataTableItem>
                            </DataTableBody>
                            {patientData.ContractStatus !== 1 && (
                                <DataTableRow>
                                  <Alert
                                      className="alert-icon"
                                      color="danger"
                                      fade={false}
                                  >
                                    <Icon name="cross-circle" />
                                    Este paciente possui restrições, favor oriente-o a
                                    entrar em contado com nossa central!
                                  </Alert>
                                </DataTableRow>
                            )}
                          </DataTable>
                      )}
                    </Col>
                  </Row>
              )}
            </Card>
          </Col>
        </Row>
        <Row>
          <Col item p={2} lg={6} xs={2}>
            <Card>
              <Row>
                <FormGroup>
                  <OutlinedSelect
                    required
                    id="partnerAddress"
                    name="partnerAddress"
                    label="Local de atendimento"
                    placeholder=""
                    size="xl"
                    value={_selectedValue(
                      addressDataOptions,
                      values?.partnerAddressId
                    )}
                    options={addressDataOptions}
                    onChange={(e) => {
                      setFieldValue("partnerAddressId", e?.value);
                    }}
                    isInvalid={errors.partnerAddress && touched.partnerAddress}
                    onBlur={handleBlur}
                  />
                </FormGroup>
                <Col>
                  <Row>
                    <Col xs={6} sm={6}>
                      <FormGroup>
                        <OutlinedInput
                          required
                          id="scheduleAtDate"
                          name="scheduleAtDate"
                          label="Data do atendimento"
                          size="xl"
                          type="date"
                          value={values.scheduleAtDate}
                          onChange={handleChange}
                          error={errors.scheduleAtDate}
                          isInvalid={
                            errors.scheduleAtDate && touched.scheduleAtDate
                          }
                          onBlur={handleBlur}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} sm={6}>
                      <FormGroup>
                        <OutlinedInput
                          required
                          id="scheduleAtTime"
                          name="scheduleAtTime"
                          type="time"
                          label="Hora do atendimento"
                          size="xl"
                          onChange={handleChange}
                          value={values.scheduleAtTime}
                          error={errors.scheduleAtTime}
                          isInvalid={
                            errors.scheduleAtTime && touched.scheduleAtTime
                          }
                          onBlur={handleBlur}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <FormGroup>
                  <OutlinedSelect
                    id="specialty"
                    name="specialty"
                    for="specialty"
                    label="Especialidade"
                    placeholder=""
                    size="xl"
                    value={_selectedValue(specialtyOptions, values?.specialty)}
                    options={specialtyOptions}
                    onChange={(e) => {
                      setFieldValue("specialty", e?.value).then();
                      _getProcedure(e?.value).then();
                    }}
                    isInvalid={errors.specialty && touched.specialty}
                    error={errors.specialty}
                    onBlur={handleBlur}
                  />
                </FormGroup>
                <FormGroup>
                  <OutlinedSelect
                    id="procedure"
                    name="procedure"
                    label="Procedimento"
                    for="procedure"
                    placeholder=""
                    size="xl"
                    value={_selectedValue(procedureOptions, values?.procedure)}
                    options={procedureOptions}
                    onChange={(e) => {
                      setFieldValue("procedure", e?.value);
                      setIsFormChk(true);
                    }}
                    isInvalid={errors.procedure && touched.procedure}
                    error={errors.procedure}
                    onBlur={handleBlur}
                  />
                </FormGroup>
                <FormGroup>
                  <OutlinedSelect
                    id="doctorUser"
                    name="doctorUser"
                    label="Profissional/Médico executante"
                    size="xl"
                    placeholder=""
                    value={_selectedValue(doctorsOptions, values.doctorUser)}
                    options={doctorsOptions}
                    onChange={(e) => {
                      setFieldValue("doctorUser", e?.value);
                    }}
                    isInvalid={errors.doctorUser && touched.doctorUser}
                    error={errors.doctorUser}
                    onBlur={handleBlur}
                  />
                </FormGroup>
                <div className="text-center">
                  <Button
                    color="primary"
                    size="large"
                    disabled={!isFormChk || patientData.ContractStatus !== 1}
                    onClick={_addProcedure}
                  >
                    ADICIONAR PROCEDIMENTO
                  </Button>
                </div>
              </Row>
            </Card>
          </Col>
          <Col item p={2} lg={6} xs={2}>
            <PreviewAltCard>
              <h6>Procedimento(s) Agendado(s)</h6>
              {services.map((item, index) => (
                <Card className="my-4" key={index}>
                  <CardTitle tag="h6">{item?.specialty.label}</CardTitle>
                  <CardSubtitle>
                    <div>{item?.procedure.label}</div>
                  </CardSubtitle>
                  <CardText className="d-flex flex-row justify-content-between align-items-baseline">
                    <span>R$ {formatNumber(item?.procedure.price)}</span>
                    <Button
                      color="transparent"
                      onClick={() => _remService(index)}
                    >
                      REMOVER
                    </Button>
                  </CardText>
                </Card>
              ))}
              <Row>
                <div className="d-flex flex-row justify-content-between">
                  <h6>Total</h6>
                  <span>R$ {formatNumber(totalServices || 0)}</span>
                </div>
              </Row>
              <div className="nk-divider divider sm"></div>
              <FormGroup>
                <Label className="form-label text-soft">Observações</Label>
                <Input
                  id="comments"
                  name="comments"
                  type="textarea"
                  maxLength={500}
                  value={values.comments}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormGroup>
              <div className="text-center">
                <Button
                  color="primary"
                  disabled={isEmpty}
                  onClick={() => setShowConfirm(true)}
                >
                  FINALIZAR E GERAR GUIA
                </Button>
              </div>
            </PreviewAltCard>
          </Col>
        </Row>
        <React.Fragment>
          <Modal
            isOpen={isShowConfirmProcedure}
            onClosed={() => setShowConfirmProcedure(false)}
          >
            <ModalHeader
              close={
                <button
                  type="button"
                  onClick={() => setShowConfirmProcedure(false)}
                  className="close"
                >
                  <Icon name="cross" />
                </button>
              }>
              Aviso
            </ModalHeader>
            <ModalBody>
              É permitido somente um Profissional/Médico executante por guia de
              atendimento. Ao selecionar um novo profissional, os procedimentos
              inseridos na lista serão apagados, deseja prosseguir?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" type="button" onClick={_checkDoctorInput}>
                CONFIRMAR
              </Button>
              <Button
                color="danger"
                onClick={() => setShowConfirmProcedure(false)}
              >
                CANCELAR
              </Button>
            </ModalFooter>
          </Modal>
        </React.Fragment>
        <React.Fragment>
          <Modal
            onSubmit={handleSubmit}
            isOpen={isShowConfirm}
            onClosed={() => setShowConfirm(false)}>
            <ModalHeader
              close={
                <button
                  type="button"
                  onClick={() => setShowConfirm(false)}
                  className="close">
                  <Icon name="cross" />
                </button>
              }>
              GERAR GUIA DE ATENDIMENTO
            </ModalHeader>
            <ModalBody>
              Ao confirmar, você estará gerando uma guia de atendimento para o
              paciente informado, que será notificado através do App.
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleSubmit} type="button">
                CONFIRMAR
              </Button>
              <Button color="danger" onClick={() => setShowConfirm(false)}>
                CANCELAR
              </Button>
            </ModalFooter>
          </Modal>
        </React.Fragment>
      </Content>
    </React.Fragment>
  );
};

export default Index;
