export const formatNumber = (amount, decimalCount = 2, decimal = ',', thousands = '.') => {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
        negativeSign +
        (j ? i.substring(0, j) + thousands : '') +
        i.substring(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
        (decimalCount
            ? decimal +
            Math.abs(amount - i).toFixed(decimalCount).slice(2)
            : '')
    );
};

export const monthName = (monthNum = null, shortName = true) => {
    const months = [
        '',
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
    ];

    if (!monthNum || months === '') {
        return months;
    }

    let month = months[parseInt(monthNum) || 1];

    if (shortName) {
        return month.substring(0, 3);
    }

    return month;
};

export const weekdaysName = (num = null, shortName = true, upper = false) => {
    const list = [
        {value: 0, label: 'Dom'},
        {value: 1, label: 'Seg'},
        {value: 2, label: 'Ter'},
        {value: 3, label: 'Qua'},
        {value: 4, label: 'Qui'},
        {value: 5, label: 'Sex'},
        {value: 6, label: 'Sáb'},
    ];

    if (num !== null && num >= 0 && num < list.length) {
        let weekday = list[num].label;
        if (shortName) {
            return upper ? weekday.substring(0, 3).toUpperCase() : weekday.substring(0, 3);
        }
        return weekday;
    }
    return list;

};


export const listMonths = [
    {value: 0, label: 'Janeiro'},
    {value: 1, label: 'Fevereiro'},
    {value: 2, label: 'Março'},
    {value: 3, label: 'Abril'},
    {value: 4, label: 'Maio'},
    {value: 5, label: 'Junho'},
    {value: 6, label: 'Julho'},
    {value: 7, label: 'Agosto'},
    {value: 8, label: 'Setembro'},
    {value: 9, label: 'Outubro'},
    {value: 10, label: 'Novembro'},
    {value: 11, label: 'Dezembro'},
];

export const parseToDateBR = (date, showTimeIfExist = false, short = false, hiddenYear = false) => {
    if (!date) {
        return;
    }

    // verifica se possui horas
    let d = date.replace(' ', 'T').split('T');
    let t = d.length > 1 ? d[1].trim() : null;
    let arDate = d[0].split('-').reverse();

    if (short) {
        const [_d, _m, _y] = arDate;
        return `${_d} ${monthName(_m, true)} ${hiddenYear ? '' : _y.substring(2)}`;
    }

    const str = `${arDate.join('/')} ${showTimeIfExist ? t.substring(0, 5) : ''}`;
    return str.trim();
};

export const parseToISODate = (date, showTimeIfExist = false) => {
    if (date) {
        // verifica se possui horas
        let _date = date.indexOf(' ') > 0 ? date.replace(' ', 'T').split('T') : date;
        let _time = '';

        if (typeof _date === 'object') {
            _date = date[0];
            _time = date[1];
        }

        _time = showTimeIfExist ? _time : '';

        _date = date.split('/').reverse().join('-');
        let dateIso = `${_date} ${_time}`;
        return dateIso.trim();
    }
};

export const leftYears = (numberOfList = 10) => {
    const year = new Date().getFullYear();
    let years = [];
    for (let i = 0; i < numberOfList; i++) {
        years.push({
            value: i,
            label: (year - i).toString(),
        });
    }
    return years;
};

export const lastDayOfMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
};

export const getLastDayOfMonth = (month, year) => new Date(year, month, 0).getDate();

export const rangeNumber = (from, to) => {
    const num = [];

    if (from > to) {
        for (let i = from; i >= to; i--) {
            num.push(i);
        }
    } else {
        for (let i = from; i <= to; i++) {
            num.push(i);
        }
    }
    return num;
};

export const appStorage = {
    add: (key, val) => localStorage.setItem(key, (typeof val === 'object') ? JSON.stringify(val) : val.toString()),
    get: (key) => {
        return JSON.parse(localStorage.getItem(key));
    },
    rem: (key) => localStorage.removeItem(key),
    cls: () => localStorage.clear(),
};

export const secretTaxNumber = taxNumber => {
    if (!taxNumber) {
        return;
    }
    const isCompany = taxNumber.replace(/\D/g, '').length > 11;
    return `***${taxNumber.substring(3, isCompany ? 15 : 11)}-**`;
};

export const _selectedValue = (list, value) => {
    return list.filter((opt) => {
        return opt?.value.toString() === value?.toString();
    })
};