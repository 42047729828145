import React, {useLayoutEffect} from "react";
import {Routes, Route, useLocation} from "react-router-dom";

//Pages Layouts
import Layout from "../layout/Index";
import LayoutNoSidebar from "../layout/Index-nosidebar";

//Public Routes
import Login from "../layouts/login/index";
import RecuperarSenha from '../layouts/recuperarSenha/index';

//Private Routes
import Dashboard from '../layouts/dashboard';
import Agendamentos from "../layouts/agendamentos";
import Faturamento from "../layouts/faturamento";
import Bank from "../../src/layouts/bank"
import AccountEntry from '../layouts/bank/entry';
import PixKey from '../layouts/bank/pix/keys';
import PixOut from "../layouts/bank/pix/out";
import PixOutAmount from "../layouts/bank/pix/out/amount";
import PixOutResume from "../layouts/bank/pix/out/resume";
import PixOutFinish from "../layouts/bank/pix/out/finish";
import Usuario from "../layouts/usuario";
import Profile from "../layouts/usuario/profile";
import Error404Classic from "../layouts/error/404";


const Pages = () => {
    const location = useLocation();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <Routes>
            <Route path={'/'} element={<Layout/>}>
                <Route path={'/dashboard'} element={<Dashboard/>}/>
                <Route path={'/agendamentos'} element={<Agendamentos/>}/>
                <Route path={'/faturamento'} element={<Faturamento/>}/>
                <Route path={'/conta'} element={<Bank/>}/>
                <Route path={'/conta/extrato'} element={<AccountEntry/>}/>
                <Route path={'/conta/pix/chave'} element={<PixKey/>}/>
                <Route path={'/conta/pix/pagamento'} element={<PixOut/>}/>
                <Route path={'/conta/pix/pagamento/valor'} element={<PixOutAmount/>}/>
                <Route path={'/conta/pix/pagamento/resumo'} element={<PixOutResume/>}/>
                <Route path={'/conta/pix/pagamento/concluido'} element={<PixOutFinish/>}/>
                <Route path={'/usuarios'} element={<Usuario/>}/>
                <Route path={'/perfil'} element={<Profile/>}/>
            </Route>
            <Route path={'/'} element={<LayoutNoSidebar/>}>
                <Route index path={'/login'} element={<Login/>}/>
                <Route path={'/recuperar'} element={<RecuperarSenha/>}/>
                <Route path='*' element={<Error404Classic/>}/>
            </Route>
        </Routes>
    )
}

export default Pages;