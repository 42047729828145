import React from "react";
import {ToastContainer} from "react-toastify";

const Content = ({ ...props }) => {
  return (
    <div className="nk-content-wrap">
      {!props.page ? props.children : null}
      {props.page === "component" ? <div className="components-preview wide-md mx-auto">{props.children}</div> : null}
      <ToastContainer/>
    </div>
  );
};
export default Content;
