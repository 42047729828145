import * as React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../hooks/useAuth";
import { weekdaysName } from "../../helpers/commons";
import http from "../../helpers/http";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../components/block/Block";
import {
  Badge,
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Col,
  Row,
  Spinner,
  ListGroup,
  ListGroupItem,
  FormGroup,
} from "reactstrap";
import classnames from "classnames";
import OutlinedInput from "../../components/input/outlined-input/OutlinedInput";
import { toast } from "react-toastify";
import { toastOptions } from "../../helpers/presets";

const Profile = () => {
  const { user } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const [activeTab, setActivetab] = useState("1");
  const navigate = useNavigate();

  const {
    values,
    handleBlur,
    errors,
    isValid,
    touched,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      newPassword: "",
      rptPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required("Informe a nova senha")
        .length(6, "A senha deve ser formada por 6 dígitos"),
      rptPassword: Yup.string()
        .required("Repita  a nova senha")
        .length(6, "A senha deve ser formada por 6 dígitos")
        .oneOf([Yup.ref("newPassword"), null], "As senhas devem ser iguais"),
    }),
    validateOnMount: false,
    validateOnBlur: false,
    onSubmit: async (v) => {
      try {
        setLoading(true);

        await http.put(`prestadores/usuario/${user.Id}`, {
          fullName: user?.FullName,
          groupId: user?.GroupId,
          email: user?.Email,
          password: v.rptPassword,
        });

        toast.success("Salvo com sucesso", { ...toastOptions });

        navigate("/dashboard", { replace: true });
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div>
      <Head title="Usuário" />
      <BlockHead>
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle page tag={"h3"}>{user?.FullName}</BlockTitle>
            <BlockDes className="text-soft">
              <p>Informações sobre sua conta de usuário</p>
            </BlockDes>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <Card className="card-bordered">
          <div className="card-aside-wrap" id="user-detail-block">
            <div className="card-content">
              <Nav tabs className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                <NavItem className="nav-item">
                  <NavLink
                    tag="a"
                    href="#tab"
                    to={""}
                    className={classnames({ active: activeTab === "1" })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setActivetab("1");
                    }}
                  >
                    <h6>Dados Cadastrais</h6>
                  </NavLink>
                </NavItem>
                <NavItem className="nav-item">
                  <NavLink
                    to={""}
                    tag="a"
                    href="#tab"
                    className={classnames({ active: activeTab === "2" })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setActivetab("2");
                    }}
                  >

                    <h6>Segurança</h6>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
          <div className="card-inner">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Block>
                  <BlockHead className="nk-block-head-line">
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle
                          tag="h6"
                          className="overline-title text-base"
                        >
                          Informações da Conta
                        </BlockTitle>
                        <BlockDes className="text-soft">
                          <p>
                            Informações básicas de acesso que você utiliza no
                            portal do Prestador.
                          </p>
                        </BlockDes>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <div className="profile-ud-list">
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Empresa: </span>
                        <span className="profile-ud-value">
                          {user?.partner.Name}
                        </span>
                      </div>
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">
                          E-mail de acesso:
                        </span>
                        <span className="profile-ud-value">{user?.Email}</span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">
                          Perfil da conta:
                        </span>
                        <span className="profile-ud-value">
                          {user?.GroupName}
                        </span>
                      </div>
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Expediente:</span>
                        <span className="profile-ud-value">
                          {user?.Expedient[0]} às {user?.Expedient[1]}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">
                          Dias acessíveis:
                        </span>
                        <ListGroup horizontal className="g-2">
                          {user?.AccessDaysAllow.map((value, label) => {
                            const week = weekdaysName(label);
                            return (
                              <ListGroupItem key={value}>
                                <Badge pill color="primary">
                                  {week}
                                </Badge>
                              </ListGroupItem>
                            );
                          })}
                        </ListGroup>
                      </div>
                    </div>
                  </div>
                </Block>
              </TabPane>
              <TabPane tabId="2">
                <Block>
                  <BlockHead className="nk-block-head-line">
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle
                          tag="h6"
                          className="overline-title text-base"
                        >
                          Trocar senha
                        </BlockTitle>
                        <BlockDes className={"text-soft"}>
                          <p>
                            Troque sua senha caso sinta que ela esteja
                            comprometida
                          </p>
                        </BlockDes>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <OutlinedInput
                          id="newPassword"
                          label="Nova senha"
                          type="password"
                          required
                          size="xl"
                          maxLength={6}
                          value={values.newPassword}
                          onChange={handleChange}
                          isInvalid={errors.newPassword && touched.newPassword}
                          error={errors.newPassword}
                          onBlur={handleBlur}
                        />
                      </FormGroup>
                      <FormGroup>
                        <OutlinedInput
                          id="rptPassword"
                          label="Confirme a nova senha"
                          type="password"
                          required
                          size="xl"
                          maxLength={6}
                          value={values.rptPassword}
                          onChange={handleChange}
                          isInvalid={errors.rptPassword && touched.rptPassword}
                          error={errors.rptPassword}
                          onBlur={handleBlur}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Button
                          color="primary"
                          onClick={handleSubmit}
                          size="l"
                          disabled={!isValid}
                        >
                          {isLoading ? (
                            <Spinner size="sm" color="light" />
                          ) : (
                            "TROCAR SENHA"
                          )}
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Block>
              </TabPane>
            </TabContent>
          </div>
        </Card>
      </Block>
    </div>
  );
};

export default Profile;
