import React, { useCallback, useEffect, useState } from "react";
import {
  lastDayOfMonth,
  leftYears,
  listMonths,
  parseToDateBR,
  _selectedValue,
} from "../../helpers/commons";
import http from "../../helpers/http";
import { useAuth } from "../../hooks/useAuth";
import Content from "../../layout/content/Content";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../components/block/Block";
import Head from "../../layout/head/Head";
import OutlinedSelect from "../../components/select/OutlinedSelect";
import { Form, Spinner } from "reactstrap";
import { Col, Row } from "../../components/grid/Grid";
import {
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
} from "../../components/Component";
import { toast } from "react-toastify";
import { toastOptions } from "../../helpers/presets";

function Faturamento() {
  const { user } = useAuth();
  const { OwnerId } = { ...user };
  const [doctorData, setDoctorData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    serviceType: 0,
    doctorId: "",
    monthSchedule: new Date().getUTCMonth(),
    yearSchedule: new Date().getUTCFullYear(),
  });
  const [entries, setEntries] = useState([]);
  const [totalServicesSum, setTotalServicesSum] = useState(0);

  const _fetch = useCallback(async () => {
    try {
      setLoading(true);
      const { serviceType, monthSchedule, yearSchedule, doctorId } = filter;
      const dateOne = `${yearSchedule}-${monthSchedule + 1}-01`;
      const dateTwo = `${yearSchedule}-${monthSchedule + 1}-${lastDayOfMonth(
        yearSchedule,
        monthSchedule + 1
      )}`;

      let isSADT = null;
      switch (parseInt(serviceType)) {
        case 0:
          isSADT = null;
          break;
        case 1:
          isSADT = false;
          break;
        default:
          isSADT = true;
          break;
      }

      const resp = await http.get("saude/guias", {
        params: {
          partnerId: OwnerId,
          userId: (isNaN(doctorId) || parseInt(doctorId) === 0) ? null : doctorId,
          scheduledAt: `${dateOne},${dateTwo}`,
          isSADT,
          status: 1,
          orderBy: "scheduledAt|asc",
        },
      });

      const { data } = { ...resp.data };
      const empty = resp.status !== 200;
      setEntries(empty ? [] : data);

      const sumTotal = data.reduce(function (acc, value) {
        return acc + value.Total;
      }, 0);
      setTotalServicesSum(sumTotal);
    } catch (err) {
      const { errors } = err.response.data;
      for (const index in errors) {
        toast.error(errors[index], { ...toastOptions });
      }
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [filter, OwnerId]);


  useEffect(() => {
    _fetch().then();
    return () => _fetch;
  }, [filter, _fetch, totalServicesSum]);

  /**
   * Carregar a lista de médicos
   * @type {(function(): Promise<void>)|*}
   * @private
   */

  const _getDoctors = useCallback(async () => {
    try {
      const res = await http.get(`prestadores/usuarios`, {
        params: {
          ownerId: OwnerId,
          groupId: 2,
        },
      });
      const { data } = { ...res.data };
      setDoctorData(data);
    } catch (err) {
      console.log(err.response ? err.response.data.error : err);
    }
  }, [OwnerId]);

  /**
   * options - react-select
   **/
  const doctorOptions = doctorData.map((row) => ({
    value: row.Id,
    label: row.FullName,
  }));

  const serviceOptions = [
    { value: 0, label: "Todos" },
    { value: 1, label: "Consultas" },
    { value: 2, label: "SADT" },
  ];

  const monthListOptions = listMonths.map((row) => ({
    value: row.value,
    label: row.label,
  }));

  const leftYearsOptions = leftYears(4).map((row) => ({
    value: row.label,
    label: row.label,
  }));

  useEffect(() => {
    _getDoctors().then();
  }, [_getDoctors]);

  return (
    <React.Fragment>
      <Head title="Faturamento" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Faturamento
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>Controle de atendimentos realizados</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Form>
          <Row className={"g-3"}>
            <Col lg={3}>
              <OutlinedSelect
                id="FullName"
                name="Profissional"
                placeholder=""
                label="Profissional"
                size="xl"
                options={doctorOptions}
                value={_selectedValue(doctorOptions, filter?.doctorId)}
                onChange={(e) => {
                  setFilter({ ...filter, doctorId: parseInt(e?.value) });
                }}
              />
            </Col>
            <Col lg={3}>
              <OutlinedSelect
                id="serviceType"
                name="serviceType"
                label="Tipo de serviço"
                size="xl"
                placeholder=""
                options={serviceOptions}
                value={_selectedValue(serviceOptions, filter?.serviceType)}
                onChange={(e) => {
                  setFilter({ ...filter, serviceType: e?.value });
                }}
              />
            </Col>
            <Col lg={3}>
              <OutlinedSelect
                id="monthSchedule"
                name="monthSchedule"
                label="Mês de atendimento"
                size="xl"
                placeholder=""
                options={monthListOptions}
                value={_selectedValue(monthListOptions, filter?.monthSchedule)}
                onChange={(e) => {
                  setFilter({ ...filter, monthSchedule: e?.value });
                }}
              />
            </Col>
            <Col lg={3}>
              <OutlinedSelect
                id="yearSchedule"
                name="yearSchedule"
                label="Ano de atendimento"
                size="xl"
                placeholder=""
                options={leftYearsOptions}
                value={_selectedValue(leftYearsOptions, filter?.yearSchedule)}
                onChange={(e) => {
                  setFilter({ ...filter, yearSchedule: e?.value});
                }}
              />
            </Col>
          </Row>
        </Form>
        <Block>
          {entries.length > 0 && (
            <>
              <BlockTitle className="my-4" tag="h4">
                <p>
                 Total Faturado:
                  <strong className='mx-2'>
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(totalServicesSum)}
                  </strong>
                </p>
              </BlockTitle>
            </>
          )}
          <DataTable className="card-stretch mt-4">
            <DataTableBody>
              <DataTableHead>
                <DataTableRow>
                  <span className="sub-text fw-medium">Paciente</span>
                </DataTableRow>
                <DataTableRow size="mb">
                  <span className="sub-text fw-medium">Local</span>
                </DataTableRow>
                <DataTableRow size="sm">
                  <span className="sub-text fw-medium">Data</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text fw-medium">Profissional</span>
                </DataTableRow>
                <DataTableRow size="xl">
                  <span className="sub-text fw-medium">Procedimento</span>
                </DataTableRow>
                <DataTableRow size="sm">
                  <span className="sub-text fw-medium">Valor</span>
                </DataTableRow>
              </DataTableHead>
              {isLoading ? (
                <Row className="text-center mt-4">
                  <Col>
                    <Spinner color="primary" />
                  </Col>
                </Row>
              ) : entries.length === 0 ? (
                <React.Fragment>
                  <DataTableItem>
                    <DataTableRow className="text-center">
                      <h6>Não há vouchers faturados para este filtro.</h6>
                    </DataTableRow>
                  </DataTableItem>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {entries.map((row, index) => (
                    <DataTableItem key={index.toString()}>
                      <DataTableRow size="mb">
                        <span>{row.PatientName}</span>
                      </DataTableRow>
                      <DataTableRow size="mb">
                        <span>{row.PartnerBusinessName}</span>
                      </DataTableRow>
                      <DataTableRow size="sm">
                        <span>
                          {parseToDateBR(row.ServiceScheduledAt, true)}
                        </span>
                      </DataTableRow>
                      <DataTableRow size="sm">
                        <span>{row.DoctorName}</span>
                      </DataTableRow>
                      <DataTableRow size="xl">
                        <span>{row.ProcedureDescription}</span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span>
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(row.Total)}
                        </span>
                      </DataTableRow>
                    </DataTableItem>
                  ))}
                </React.Fragment>
              )}
            </DataTableBody>
          </DataTable>
        </Block>
      </Content>
    </React.Fragment>
  );
}

export default Faturamento;
