import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import http from "../../../../../helpers/http";
import { OutlinedInput, Row } from "../../../../../components/Component";
import Icon from "../../../../../components/icon/Icon";
import { toast } from "react-toastify";
import { toastOptions } from "../../../../../helpers/presets";

const PixKeyFormConfirm = ({
  onSubmit,
  isOpen,
  onClosed,
  taxNumber,
  accountKey,
  pixKeyType,
  pixKey,
}) => {
  const [isLoading, setLoading] = useState(false);

  const { handleSubmit, handleChange, isValid, values, errors } = useFormik({
    initialValues: {
      confirmationCode: "",
    },
    validationSchema: Yup.object().shape({
      confirmationCode: Yup.number().required(
        "Informe o código de confirmação"
      ),
    }),
    onSubmit: async (v) => {
      try {
        setLoading(true);
        const { confirmationCode } = v;
        await http.post("banco/pix/chave/confirmar", {
          accountKey,
          taxNumber,
          pixKeyType,
          pixKey,
          confirmationCode,
        });
        if (onSubmit) onSubmit();
        onClosed();
      } catch (e) {
        toast.error(e.response.data.errors[0], { ...toastOptions });
      } finally {
        setLoading(false);
      }
    },
  });

  const handleResend = async () => {
    try {
      setLoading(true);
      await http.post("/banco/pix/chave/token", {
        accountKey,
        taxNumber,
        pixKeyType,
        pixKey,
      });

      toast.success(`Código reenviado para ${pixKey}`, {
        ...toastOptions,
      });
    } catch (e) {
      console.log(e);
      toast.error(e, { ...toastOptions });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClosed={onClosed} disableEscapeKeyDown>
      <ModalHeader
        close={
          <button onClick={onClosed} className="close">
            <Icon name="cross" />
          </button>
        }
      >
        Registrar Chave Pix
      </ModalHeader>
      <ModalBody>
        <Row className="g-1">
          <span>
            Informe o código de confirmação enviado para{" "}
            <strong>{pixKey}</strong>
          </span>
          {values.pixKeyType !== "4" && (
            <OutlinedInput
              id="confirmationCode"
              type="text"
              label="Código de confirmação"
              required
              size="xl"
              error={typeof errors.confirmationCode !== "undefined"}
              helperText={errors.confirmationCode}
              value={values.confirmationCode}
              onChange={handleChange}
            />
          )}

          <Button
            className="btn-dim text-primary"
            color="transparent"
            onClick={handleResend}
          >
            Reenviar o código
          </Button>
        </Row>
        <Button
          size="xl"
          color="primary"
          disabled={!isValid}
          loading={isLoading}
          onClick={() => handleSubmit()}
        >
          {isLoading ? <Spinner size="sm" color="light" /> : "CONFIRMAR"}
        </Button>
      </ModalBody>
    </Modal>
  );
};

PixKeyFormConfirm.PropType = {
  isOpen: PropTypes.bool.isRequired,
  anchor: PropTypes.string.isRequired,
  taxNumber: PropTypes.string.isRequired,
  accountKey: PropTypes.string.isRequired,
  pixKeyType: PropTypes.number.isRequired,
  pixKey: PropTypes.string.isRequired,
  onClosed: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};

export default PixKeyFormConfirm;
