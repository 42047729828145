import React, {useEffect} from "react";
import {Outlet} from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import {Navigate, useLocation} from 'react-router-dom';
import {useAuth} from '../hooks/useAuth';

const Layout = ({title, ...props}) => {
    useEffect(() => {
        document.body.classList.add("has-aside");
        document.body.classList.remove("npc-apps", "apps-only")
    }, []);

    const {user} = useAuth();
    const location = useLocation();
    const {pathname} = location;
    const {Routes} = {...user};

    if (!user) {
        // user is not authenticated
        return <Navigate to="/login"/>;
    }

    let allowed = (pathname === '/');

    if (!allowed) {
        for (const r of Routes) {
            const match = pathname.match(r.substring(1));
            const {index} = {...match};
            if (index > 0) {
                allowed = true;
                break;
            }
        }
    }

    if (!allowed) {
        return <Navigate to="/"/>;
    }

    return (
        <React.Fragment>
            <Head title={!title && 'Loading'}/>
            <AppRoot>
                <AppMain>
                    <AppWrap>
                        <Header fixed/>
                        <div className="nk-content">
                            <div className="container wide-xl">
                                <div className="nk-content-inner">
                                    <Sidebar/>
                                    <div className="nk-content-body">
                                        <Outlet/>
                                        <Footer/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AppWrap>
                </AppMain>
            </AppRoot>
        </React.Fragment>
    );
};
export default Layout;
