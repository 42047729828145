/**
 * @author: Alexandre G R Alves
 */

import React, {useEffect, useState} from "react";
import {Icon} from "../Component";
import {Spinner} from "reactstrap";
import Select from "react-select";

const OutlinedSelect = React.forwardRef((props, ref) => {
    const {
        label,
        size,
        icon,
        error,
        isInvalid,
        defaultValue,
        loading = false,
        ...rest
    } = props;
    const [focus, setFocus] = useState(false);

    useEffect(() => {
        setFocus(true);
    }, [])

    const _handleFocus = (e) => {
        setFocus(true);
        if (rest.onFocus) rest.onFocus(e);
    }

    const _handleBlur = (e) => {
        setFocus(rest.value);
        if (rest.onBlur) rest.onBlur(e);
    }

    return (
        <div className={`form-control-wrap ${focus ? "focused" : ""}`}>
            {(icon || loading) && (
                <div className="form-icon form-icon-right xl">
                    {icon ? <Icon name={icon}></Icon> : <Spinner color="primary" size={'sm'}/>}
                </div>
            )}
            <Select ref={ref}
                    {...rest}
                    className={`react-select-container ${size ? `react-select-container-${size}` : ''}`}
                    classNamePrefix={`react-select`}
                    onFocus={_handleFocus}
                    onBlur={_handleBlur}
                    isLoading={loading}
                    isClearable={true}
                    loadingMessage={() => 'Carregando'}/>
            <label className="form-label-outlined z-3" htmlFor={props.id}>
                {label}
            </label>
            {isInvalid && error && <span className="invalid">{error}</span>}
        </div>
    );
});

export default OutlinedSelect;
