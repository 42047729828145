import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { formatNumber } from "../../../../helpers/commons";
import { useLocation, useNavigate } from "react-router-dom";
import http from "./../../../../helpers/http";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../../../components/block/Block";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { Col, Row } from "../../../../components/grid/Grid";
import { Button, OutlinedInput } from "../../../../components/Component";
import { Spinner } from "reactstrap";
import {toast} from "react-toastify";
import {toastOptions} from "../../../../helpers/presets";

const PixOutAmount = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const firstName = state.ReceiverName.split(" ")[0];
  const balance = formatNumber(state.AccountBalance);
  const [isLoading, setLoading] = useState(false);


  const {
    values,
    errors,
    isValid,
    handleSubmit,
    handleBlur,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: {
      amount: "",
    },
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
      amount: Yup.string()
        .test("rangeValue", "Valor fora do limite permitido", (value) => {
          if (!value) {
            return false;
          }
          const decimal = value.replace(/\D/g, "") / 100;

          if (decimal > state?.AccountBalance) {
            return false;
          }

          return decimal >= 1 && decimal <= 100000;
        })
        .required("Informe um valor"),
    }),
    onSubmit: async (v) => {
      try {
        setLoading(true);
        const resp = await http.get("util/bancos", {
          params: { code: state.ReceiverBank },
        });
        const { data } = resp.data;
        const BankInfo = data[0];
        const Amount = v.amount.replace(/\D/g, "") / 100;
        navigate("/conta/pix/pagamento/resumo", {
          state: { Amount, BankInfo, ...state },
        });
      } catch (e) {
        toast.error(e.response.data.errors[0],{...toastOptions});
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <React.Fragment>
      <Head title="Pagamento Pix" />
      <Content>
        <BlockHead>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>
                Quanto você deseja transferir para ${firstName}?
              </BlockTitle>
              <BlockDes className={"text-soft"}>
                <p>Seu saldo em conta é de R$ ${balance}</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Row>
          <Col>
            <OutlinedInput
              id="amount"
              name="amount"
              label="Informe um valor de R$ 1,00 a R$ 10.000,00"
              value={values.amount}
              prefix={"R$"}
              decimalseparator={","}
              thousandseparator={"."}
              decimalscale={2}
              onBlur={handleBlur}
              valueIsNumericString={true}
              allownegative="false"
              error={errors.amount && touched.amount}
              helperText={errors.amount}
              onChange={(e) => setFieldValue("amount", e.value)}
            />
            <Button
              size="l"
              disabled={!isValid}
              loading={isLoading}
              onClick={handleSubmit}
              color="primary"
            >
              {isLoading ? <Spinner size="sm" color="light" /> : "CONTINUAR"}
            </Button>
          </Col>
        </Row>
      </Content>
    </React.Fragment>
  );
};

export default PixOutAmount;
