import React, { useCallback, useEffect, useState } from "react";
import http from "./../../../../helpers/http";
import { pixKeysStatus, pixKeysTypes } from "../../../../helpers/types";
import PixKeyForm from "./components/PixKeyForm";
import PixKeyFormConfirm from "./components/PixKeyFormConfirm";
import { useAuth } from "../../../../hooks/useAuth";
import Content from "../../../../layout/content/Content";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../../../components/block/Block";
import { Col, Row } from "../../../../components/grid/Grid";
import { Button, Spinner } from "reactstrap";
import Head from "../../../../layout/head/Head";
import Icon from "../../../../components/icon/Icon";
import { toast } from "react-toastify";
import { toastOptions } from "../../../../helpers/presets";

const BancoPix = () => {
  const { user } = useAuth();
  const { PartnerTaxNumber, BankAccountKey } = user;
  const [isLoading, setLoading] = useState(false);
  const [isOpenForm, setOpenForm] = useState(false);
  const [isOpenFormConfirm, setOpenFormConfirm] = useState(false);
  const [dataKeys, setDataKeys] = useState([]);
  const [pixKeyData, setPixKeyData] = useState({});

  const fetchPixKey = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await http.get("banco/pix/chaves", {
        params: {
          taxNumber: PartnerTaxNumber,
          accountKey: BankAccountKey,
          status: "Registering, Registered",
        },
      });
      const { data } = resp.data;
      setDataKeys(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [BankAccountKey, PartnerTaxNumber]);

  useEffect(() => {
    fetchPixKey().then();
  }, [fetchPixKey]);

  const _handleDelete = async ({ PixKey, PixKeyType }) => {
    try {
      setLoading(true);
      await http.delete("banco/pix/chave", {
        data: JSON.stringify({
          taxNumber: PartnerTaxNumber,
          accountKey: BankAccountKey,
          pixKey: PixKey,
          pixKeyType: PixKeyType,
        }),
      });
      toast.success("Chave Pix removida!", { ...toastOptions });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = ({ PixKey }) => {
    navigator.clipboard.writeText(PixKey);
    toast.success("Chave copiada para área de transferência!", {
      ...toastOptions,
    });
  };

  return (
    <React.Fragment>
      <Head title="Chaves Pix" />
      <Content>
        <BlockHead>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag={"h3"}>
                Chaves Pix
              </BlockTitle>
              <BlockDes className={"text-soft"}>
                <p>Você pode cadastrar até 10 chaves.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <div className="toggle-expand-content">
                  <ul className="nk-block-tools">
                    <li className="nk-block-tools-opt">
                      <Col>
                        <Button
                          color="primary"
                          className="btn-dim"
                          onClick={() => setOpenForm(true)}
                        >
                          CADASTRAR NOVA CHAVE
                        </Button>
                        <Button
                          color="primary"
                          className="btn-dim"
                          onClick={() => setOpenFormConfirm(true)}
                        >
                          MODAL CONFIRM
                        </Button>
                      </Col>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Row p={4}>
          {isLoading ? (
            <Row className="text-center mt-4">
              <Col>
                <Spinner color="primary" />
              </Col>
            </Row>
          ) : (
            <Col flex={1} display="flex" flexDirection="column" my={4}>
              {dataKeys.map((item) => (
                <React.Fragment key={item.PixKey}>
                  <Row>
                    <Col>
                      <h4>{item.PixKey}</h4>
                      <h4>
                        {pixKeysTypes[item.PixKeyType]} |{" "}
                        {pixKeysStatus[item.Status]}
                      </h4>
                    </Col>
                    <Button
                      className="btn-round btn-icon"
                      color="primary"
                      size="lg"
                      onClick={() => _handleDelete(item)}
                    >
                      <Icon name="edit" />
                    </Button>
                    <Button
                      className="btn-round btn-icon"
                      color="primary"
                      size="lg"
                      onClick={() => handleCopy(item)}
                    >
                      <Icon name="trash" />
                    </Button>
                  </Row>
                </React.Fragment>
              ))}
            </Col>
          )}
          <PixKeyForm
            anchor="bottom"
            isOpen={isOpenForm}
            onClosed={() => setOpenForm(false)}
            taxNumber={PartnerTaxNumber}
            accountKey={BankAccountKey}
            onSubmit={(res) => {
              setOpenForm(false);
              if (parseInt(res.pixKeyType) !== 4) {
                setPixKeyData(res);
                setOpenFormConfirm(true);
              } else {
                fetchPixKey().then();
              }
            }}
          />

          <PixKeyFormConfirm
            anchor="right"
            isOpen={isOpenFormConfirm}
            onClosed={() => setOpenFormConfirm(false)}
            pixKeyType={pixKeyData.pixKeyType}
            pixKey={pixKeyData.pixKey}
            taxNumber={PartnerTaxNumber}
            accountKey={BankAccountKey}
            onSubmit={fetchPixKey}
          />
        </Row>
      </Content>
    </React.Fragment>
  );
};

export default BancoPix;
