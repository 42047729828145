import React from 'react';
// Contexts do app
const AppContext = React.createContext(null);

// Nome de exibição do contexto
AppContext.displayName = 'TSPrestadorContext';

// Context de dados do aplicativo
const AppContextProvider = ({children}) => {
  const [isShowMenu, setShowMenu] = React.useState(false);
  const [appData, setAppData] = React.useState({
    layout: 'default',
  });
  const userData = React.useMemo(() => {
    return JSON.parse(localStorage.getItem('UserData'));
  }, []);

  // Global states
  const value = React.useMemo(() => ({
    appData, setAppData,
    isShowMenu, setShowMenu,
    userData,
  }), [appData, isShowMenu, setShowMenu, userData]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export {
  AppContext,
  AppContextProvider,
};
