const statusVoucher = [
  "Pendente",
  "Autorizado",
  "Atendido",
  "Cancelado",
  "Expirado",
  "Em análise",
  "Processando",
];

const statusVoucherColor = [
  "warning",
  "success",
  "success",
  "danger",
  "info",
  "warning",
  "info",
];

const subjectPronouns = ["Sr", "Sra", "Dr", "Dra"];

const pixKeysTypes = [
  { value: 0, label: "CPF" },
  { value: 1, label: "CNPJ" },
  { value: 2, label: "E-mail" },
  { value: 3, label: "Celular" },
  { value: 4, label: "Chave aleatória" },
];

const pixKeysStatus = {
  Registering: "Registrando",
  Canceled: "Cancelada",
  Registered: "Registada",
  Created: "Criada",
  ErrorOwnership: "Erro de propriedade",
  ErrorPortability: "Erro de portabilidade",
};

export {
  statusVoucher,
  statusVoucherColor,
  subjectPronouns,
  pixKeysTypes,
  pixKeysStatus,
};
