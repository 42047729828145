import React, { useCallback, useEffect, useState } from "react";
import http from "../../helpers/http";
import { useAuth } from "../../hooks/useAuth";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../components/block/Block";
import {
  Col,
  DataTableBody,
  DataTableItem,
  DataTableRow,
  Row,
  UserAvatar,
} from "../../components/Component";
import UserForm from "./components/UserForm";
import Icon from "../../components/icon/Icon";
import { Badge, Button, Spinner } from "reactstrap";
import { findUpper } from "../../helpers/utils";
import {toast} from "react-toastify";
import {toastOptions} from "../../helpers/presets";

const Usuario = () => {
  const { user } = useAuth();
  const { OwnerId } = user;
  const DEFAULT = {
    Id: null,
    PersonId: OwnerId,
    GroupId: "1",
    SubjectPronouns: "",
    FullName: "",
    Email: "",
    AccessDaysAllow: [],
    AccessTimeStart: "",
    AccessTimeEnd: "",
    IsActive: false,
  };
  const [userData, setUserData] = useState(DEFAULT);
  const [userForm, setUserForm] = useState(false);
  const [fetchData, setFetchData] = useState([]);
  const [isLoading, setLoading] = useState(false);


  const _fetch = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await http.get(`prestadores/usuarios`, {
        params: {
          ownerId: OwnerId,
        },
      });
      const { data } = resp.data;
      setFetchData(resp.status === 200 ? data : []);
    } catch (err) {
      const { errors } = err.response.data;
      for (const index in errors) {
        toast.error(errors[index], { ...toastOptions });
      }
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [OwnerId]);

  useEffect(() => {
    _fetch().then();
    return () => _fetch;
  }, [_fetch]);

  const handleVisibleForm = (row) => {
    if (row) {
      setUserData({ UserId: row.Id, OwnerId });
    }
    setUserForm(true);
  };

  return (
    <React.Fragment>
      <Head title="Usuários"/>
      <Content>
        <BlockHead>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag={"h3"}>
                Contas de usuários
              </BlockTitle>
              <BlockDes className={"text-soft"}>
                <p>Relação de usuários utilizadores do sistema</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <div className="toggle-expand-content">
                  <ul className="nk-block-tools">
                    <li className="nk-block-tools-opt">
                      <Button
                        outline
                        color="primary"
                        onClick={() => handleVisibleForm(DEFAULT)}
                      >
                        NOVO USUÁRIO
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row>
            {isLoading && (
                <Row className="text-center mt-4">
                  <Col>
                    <Spinner color="primary" />
                  </Col>
                </Row>
            )}
            <DataTableBody>
              {fetchData.map((row, index) => (
                  <DataTableItem key={index}>
                    <DataTableRow size="lg">
                      <div className="user-card">
                        <UserAvatar
                            text={findUpper(row.FullName)}
                            image={row.Avatar}
                        ></UserAvatar>
                        <div className="user-info">
                          <span className="tb-lead">{row.FullName}</span>
                          <span>{row.GroupName}</span>
                        </div>
                      </div>
                    </DataTableRow>
                    <DataTableRow size="sm">
                      <Badge color={row.IsActive ? "success" : "danger"}>
                        {row.IsActive ? "Ativo" : "Bloqueado"}
                      </Badge>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <Button
                          className="btn-round btn-icon"
                          color="primary"
                          size="lg"
                          onClick={() => handleVisibleForm(row)}
                      >
                        <Icon name="edit" />
                      </Button>
                    </DataTableRow>
                    <li className="divider"></li>
                  </DataTableItem>
              ))}
            </DataTableBody>
          </Row>
          {userData && (
              <UserForm
                  isOpen={userForm}
                  onClosed={() => setUserForm(false)}
                  userData={userData}
                  onAfterSave={() => {
                    _fetch().then(() => {
                      setUserForm(false);
                    });
                  }}
              />
          )}
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Usuario;
