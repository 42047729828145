import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import http from "../../../../../helpers/http";
import { CELPHONE } from "../../../../../helpers/masks";
import { Row } from "../../../../../components/grid/Grid";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { OutlinedInput } from "../../../../../components/Component";
import Icon from "../../../../../components/icon/Icon";
import {toast} from "react-toastify";
import {toastOptions} from "../../../../../helpers/presets";

const allowPixKeys = [];
allowPixKeys[2] = "E-mail";
allowPixKeys[3] = "Celular";
allowPixKeys[4] = "Chave aleatória";

const PixKeyForm = ({ onSubmit, isOpen, onClosed, taxNumber, accountKey }) => {
  const [isLoading, setLoading] = useState(false);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    resetForm,
    isValid,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      taxNumber,
      accountKey,
      pixKeyType: "",
      pixKey: "",
    },
    validationSchema: Yup.object().shape({
      pixKeyType: Yup.number().required("Informe um tipo de chave"),
      pixKey: Yup.string().when("pixKeyType", {
        is: (value) => {
          return value === 2 || value === 3;
        },
        then: Yup.string().required("Informe o valor da chave"),
        otherwise: Yup.string(),
      }),
    }),
    onSubmit: async (v) => {
      try {
        setLoading(true);
        await http.post("banco/pix/chave", {
          ...v,
          pixKey: v.pixKeyType === 4 ? "-" : v.pixKey,
        });
        onSubmit(v);
      } catch (e) {
        toast.error(e.response.data.errors[0],{...toastOptions});
      } finally {
        setLoading(false);
        resetForm();
      }
    },
  });

  return (
    <Modal isOpen={isOpen} onClosed={onClosed}>
      <ModalHeader
        close={
          <button onClick={onClosed} className="close">
            <Icon name="cross" />
          </button>
        }
      >
        Registrar Chave Pix
      </ModalHeader>
      <ModalBody>
        <Row>
          <div
            className={typeof errors.pixKeyType !== "undefined" ? "error" : ""}
          >
            <label id="pixKeyTypeLabel">Informe o tipo da chave</label>

            <div id="pixKeyTypeHelper" className="helper-text">
              {errors.pixKeyType}
            </div>
            {allowPixKeys.map((type, key) => (
              <div key={type} className="my-1">
                <div className="custom-control custom-control-sm custom-radio">
                  <input
                    type="radio"
                    id={`pixKey_${key}`}
                    name="pixKeyType"
                    value={key.toString()}
                    checked={values.pixKeyType === key.toString()}
                    onChange={handleChange}
                    className="custom-control-input"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`pixKey_${key}`}
                  >
                    {type}
                  </label>
                </div>
              </div>
            ))}
          </div>
          <Row className="my-4">
            {values.pixKeyType !== 4 && (
              <OutlinedInput
                id="pixKey"
                mask={values.pixKeyType === 3 ? CELPHONE : false}
                size="xl"
                label="Chave Pix"
                value={values.pixKey}
                error={errors.pixKey && touched.pixKey}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}
          </Row>
        </Row>
        <Button
          size="l"
          color="primary"
          disabled={!isValid}
          loading={isLoading}
          onClick={() => handleSubmit()}
        >
          CONTINUAR
        </Button>
      </ModalBody>
    </Modal>
  );
};

PixKeyForm.propType = {
  isOpen: PropTypes.bool.isRequired,
  anchor: PropTypes.string.isRequired,
  taxNumber: PropTypes.string.isRequired,
  accountKey: PropTypes.string.isRequired,
  onClosed: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};

export default PixKeyForm;
