import Head from "../../layout/head/Head";
import React, { useState } from "react";
import Content from "../../layout/content/Content";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../images/logo2x.png";
import LogoDark from "../../images/logo2x.png";
import {
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
} from "../../components/block/Block";
import {
  Button,
  Block,
  Col,
  OutlinedInput,
  PreviewCard,
  Row,
  Icon,
} from "../../components/Component";
import { Form, FormGroup, Spinner } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import http from "../../helpers/http";
import { toast } from "react-toastify";
import { toastOptions } from "../../helpers/presets";

const RecuperarSenha = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [showPwd, setShowPwd] = useState(false);
  const [tokenInput, setTokenInput] = useState(false);

  const {
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
    isValid,
    handleSubmit,
  } = useFormik({
    initialValues: {
      login: "",
      newPassword: "",
      token: "",
    },
    validationSchema: Yup.object({
      login: Yup.string()
        .email("Formato inválido")
        .required("Informe seu e-mail de usuário."),
      newPassword: Yup.string().required("O campo nova senha é obrigatório"),
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const endpoint = "prestadores/usuario/senha/criar";
        const d = {
          login: values.login,
        };
        if (!tokenInput) {
          d.newPassword = values.newPassword;
          toast.success(
            `Enviamos o código de confirmação para ${values.login}`,
            {
              ...toastOptions,
            }
          );
          setTokenInput(true);
          return;
        } else {
          d.token = values.token;
        }
        await http.post(endpoint, d);
        if (tokenInput) {
          toast.success("Senha confirmada com sucesso", {
            ...toastOptions,
          });
          navigate("/");
        }
      } catch (err) {
        const { errors } = err.response.data;
        for (const index in errors) {
          toast.error(errors[index], { ...toastOptions });
        }
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <React.Fragment>
      <Content>
        <Head title="Criar nova senha" />
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo py-5 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img
                className="logo-light logo-img logo-img-lg"
                src={Logo}
                alt="logo"
              />
              <img
                className="logo-dark logo-img logo-img-lg"
                src={LogoDark}
                alt="logo-dark"
              />
            </Link>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Recuperar dados de acessos</BlockTitle>
                <BlockDes>
                  {!tokenInput && (
                    <p>As informações serão enviadas para o e-mail abaixo.</p>
                  )}
                  {tokenInput && (
                    <p>Criar senha para autenticar no Portal do Prestador.</p>
                  )}
                </BlockDes>
              </BlockContent>
            </BlockHead>
            <Form className="is-alter" onSubmit={handleSubmit}>
              <Row mt={4}>
                <Col>
                  {!tokenInput && (
                    <>
                      <FormGroup>
                        <OutlinedInput
                          type={"login"}
                          name={"login"}
                          label={"E-mail"}
                          size={"xl"}
                          required
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.login}
                          isInvalid={errors.login && touched.login}
                          error={errors.login}
                        />
                      </FormGroup>
                      <FormGroup>
                        <OutlinedInput
                          type={showPwd ? "text" : "password"}
                          name={"newPassword"}
                          label={"Nova senha"}
                          size={"xl"}
                          iconRight={
                            <Button
                              type={"button"}
                              onClick={() => setShowPwd(!showPwd)}
                            >
                              <Icon name={showPwd ? "eye-off" : "eye"} />
                            </Button>
                          }
                          required
                          maxLength={6}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.newPassword}
                          isInvalid={errors.newPassword && touched.newPassword}
                          error={errors.newPassword}
                        />
                      </FormGroup>
                    </>
                  )}
                  {tokenInput && (
                    <>
                      <FormGroup>
                        <OutlinedInput
                          name={"token"}
                          label={"Informe o código de confirmação"}
                          size={"xl"}
                          maxLength={"6"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.token}
                          isInvalid={errors.token && touched.token}
                          error={errors.token}
                        />
                      </FormGroup>
                    </>
                  )}
                  <Button
                    size="lg"
                    className="btn-block"
                    color="primary"
                    type="submit"
                    disabled={!isValid || isLoading}
                  >
                    {isLoading && <Spinner size="sm" color="light" />}
                    {tokenInput ? "CONFIRMAR" : "CRIAR SENHA"}
                  </Button>
                  <div className="form-note-s2 text-center pt-4">
                    <Link to={`${process.env.PUBLIC_URL}/login`}>
                      Voltar para Login
                    </Link>
                  </div>
                </Col>
              </Row>
            </Form>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default RecuperarSenha;
