
const menu = [
  {
    icon: "dashboard",
    text: "Dashboard",
    link: "/dashboard",
  },
  {
    icon: "plus-medi",
    text: "Agendamentos",
    link: "/agendamentos",
  },
  {
    icon: "growth",
    text: "Faturamento",
    link: "/faturamento",
  },
  // {
  //   icon: "coins",
  //   text: "Conta Digital",
  //   link: "/conta",
  // },
  {
    icon: "users",
    text: "Usuários",
    link: "/usuarios",
  },

];
export default menu;
