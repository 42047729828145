import {
  createContext,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useNavigate  } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import http from "../helpers/http";
const AuthContext = createContext(undefined);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const Login = useCallback(
    async (data) => {
      try {
        const { OwnerId } = { ...data };
        const resp = await http.get(`parceiro/${OwnerId}`);
        const partner = { ...resp.data.data };
        setUser({...data, partner });
        navigate("/dashboard", { replace: true });
      } catch (e) {
        console.log(e);
      }
    },
    [navigate, setUser]
  );

  // call this function to sign out logged in user
  const logout = useCallback(() => {
    setUser(null);
    navigate("/", { replace: true });
  }, [navigate, setUser]);

  const value = useMemo(
    () => ({
      user,
      Login,
      logout,
    }),
    [Login, logout, user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
