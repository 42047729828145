import React, { useState } from "react";
import Logo from "../../images/logo2x.png";
import LogoDark from "../../images/logo2x.png";
// NPM
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
// dash
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Col,
  Icon,
  OutlinedInput,
  PreviewCard,
  Row,
} from "../../components/Component";
import { Form, FormGroup, Spinner } from "reactstrap";
// Custom
import http from "../../helpers/http";
import { useAuth } from "../../hooks/useAuth";
import Head from "../../layout/head/Head";
import { toast } from "react-toastify";
import { toastOptions } from "../../helpers/presets";
import Content from "../../layout/content/Content";

const YEAR = new Date().getFullYear();

const Login = () => {
  const { Login } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [showPwd, setShowPwd] = useState(false);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
    isValid,
  } = useFormik({
    initialValues: {
      login: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      login: Yup.string().email("E-mail inválido").required("Informe o e-mail"),
      password: Yup.string().min(
        6,
        "A senha deve conter no mínimo 6 caracteres"
      ),
    }),
    onSubmit: async (v) => {
      try {
        setIsLoading(true);
        const { login, password } = v;
        const resp = await http.post("prestadores/usuario/autenticar", {
          login,
          password,
        });
        const { data } = resp.data;
        // armazena no appStorage
        Login(data);
      } catch (err) {
        const { errors } = err.response.data;
        for (const index in errors) {
          toast.error(errors[index], { ...toastOptions });
        }
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <React.Fragment>
      <Content>
        <Head title="Login" />
        <Block className="nk-block-middle nk-auth-body wide-xs">
          <div className="brand-logo py-5 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img
                className="logo-light logo-img logo-img-lg"
                src={Logo}
                alt="logo"
              />
              <img
                className="logo-dark logo-img logo-img-lg"
                src={LogoDark}
                alt="logo-dark"
              />
            </Link>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Portal do Prestador</BlockTitle>
                <BlockDes>
                  <p>Entre com suas credenciais</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            <Form className="is-alter" onSubmit={handleSubmit}>
              <Row mt={4}>
                <Col>
                  <FormGroup>
                    <OutlinedInput
                      type={"login"}
                      name={"login"}
                      label={"E-mail"}
                      size={"xl"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.login}
                      isInvalid={errors.login && touched.login}
                      error={errors.login}
                    />
                  </FormGroup>
                  <FormGroup>
                    <OutlinedInput
                      type={showPwd ? "text" : "password"}
                      name={"password"}
                      label={"Senha"}
                      size={"xl"}
                      iconRight={
                        <Button
                          type={"button"}
                          onClick={() => setShowPwd(!showPwd)}
                        >
                          <Icon name={showPwd ? "eye-off" : "eye"} />
                        </Button>
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      isInvalid={errors.password && touched.password}
                      error={errors.password}
                      maxLength={6}
                    />
                    <Link
                      className="link link-primary link-sm"
                      to={`${process.env.PUBLIC_URL}/recuperar`}
                    >
                      Esqueci minha senha?
                    </Link>
                  </FormGroup>
                  <FormGroup>
                    <Button
                      type="submit"
                      size="lg"
                      className="btn-block"
                      color="primary"
                      disabled={!isValid || isLoading}
                    >
                      {isLoading ? (
                        <Spinner size="sm" color="light" />
                      ) : (
                        "ENTRAR"
                      )}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
              <div className="text-center pt-4" color="text.light">
                <span> © {YEAR} | TokSaúde</span>
              </div>
            </Form>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Login;
