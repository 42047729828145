import React from "react";
import { AuthProvider } from "./hooks/useAuth";
import ThemeProvider from "./layout/provider/Theme";
import Router from "../src/route/index";

export default function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <Router />
      </AuthProvider>
    </ThemeProvider>
  );
}
