//theme
import { Modal, ModalBody, ModalHeader} from "reactstrap";
import Icon from "../../../components/icon/Icon";
import React, { useCallback, useEffect, useState } from "react";
// custom
import "react-loading-skeleton/dist/skeleton.css";
import http from "../../../helpers/http";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../../components/block/Block";
import { parseToDateBR } from "../../../helpers/commons";


const VoucherDetails = ({ isOpen, onClosed, voucherData }) => {
  const [voucher, setVoucher] = useState();
  const [voucherServices, setVoucherServices] = useState([]);
  const { VoucherId } = { ...voucherData };

  const _fetchVoucher = useCallback(async () => {
    try {
      if (!VoucherId) return;

      const resp = await http.get(`/saude/guia/${VoucherId}`);
      const { data } = resp.data;
      setVoucher(data);
      let servicesData = data.ServicesInfo;
      setVoucherServices(servicesData);
    } catch (err) {
      console.log(err);
    }
  }, [VoucherId]);

  useEffect(() => {
    const fn = async () => {
      await _fetchVoucher();
    };
    fn().then();
    return () => fn;
  }, [_fetchVoucher, VoucherId]);

  return (
    <Modal size="lg" isOpen={isOpen} onClosed={onClosed}>
      <ModalHeader
        close={
          <button onClick={onClosed} className="close">
            <Icon name="cross" />
          </button>
        }
      >
        Detalhes da Guia
      </ModalHeader>
      <ModalBody>
        <Block>
          <BlockHead className="nk-block-head-line">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h6" className="overline-title text-base">
                  Dados do Paciente
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <div className="profile-ud-list">
            <div className="profile-ud-item">
              <div className="project-info">
                <span className="title">Paciente</span>
                <span className="sub-text">
                  {voucher?.PatientInfo.FullName}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="project-info">
                <span className="title">Matrícula</span>
                <span className="sub-text">
                  {voucher?.PatientInfo.HealthInsuranceNumber}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="project-info">
                <span className="title">CPF</span>
                <span className="sub-text">
                  {voucher?.PatientInfo.TaxNumber}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="project-info">
                <span className="title">Telefone</span>
                <span className="sub-text">
                  {voucher?.PatientInfo.Contacts.Phone}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="project-info">
                <span className="title">Email</span>
                <span className="sub-text">
                  {voucher?.PatientInfo.Contacts.Email}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="project-info">
                <span className="title">Data de nascimento</span>
                <span className="sub-text">
                  {parseToDateBR(voucher?.PatientInfo.BirthDate)}
                </span>
              </div>
            </div>
          </div>
        </Block>
        <div className="nk-divider divider md"></div>
        <Block>
          <BlockHead className="nk-block-head-line">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h6" className="overline-title text-base">
                  Dados do Agendamento
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <div className="profile-ud-list">
            <div className="profile-ud-item">
              <div className="project-info">
                <span className="title"> Médico/Profissional Executante</span>
                <span className="sub-text">{voucher?.DoctorName}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="project-info">
                <span className="title">Data</span>
                <span className="sub-text">
                  {parseToDateBR(voucher?.ScheduledAt, true)}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="project-info">
                <span className="title">Status</span>
                <span className="sub-text">{voucher?.StatusText}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="project-info">
                <span className="title">Total</span>
                <span className="sub-text">
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(voucher?.InvoiceInfo.Total)}
                </span>
              </div>
            </div>
          </div>
          {voucher?.Comments && (
            <BlockHead className="nk-block-head-line py-1">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle tag="span">Observações</BlockTitle>
                  <span className="sub-text">{voucher?.Comments}</span>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>
          )}
        </Block>
        <div className="nk-divider divider md"></div>
        <Block>
          <BlockHead className="nk-block-head-line">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h6" className="overline-title text-base">
                  Dados de Serviço(s)
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          {voucherServices?.map((service, index) => (
            <>
              <div key={index}>
                <div className="profile-ud-list">
                  <div className="profile-ud-item">
                    <div className="project-info">
                      <span className="title">Procedimento</span>
                      <span className="sub-text">{service.Procedure}</span>
                    </div>
                  </div>
                  <div className="profile-ud-item">
                    <div className="project-info">
                      <span className="title">Valor</span>
                      <span className="sub-text">
                        {new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(service.OriginalPrice)}
                      </span>
                    </div>
                  </div>
                  <div className="profile-ud-item">
                    <div className="project-info">
                      <span className="title">Especialidade</span>
                      <span className="sub-text"> {service.Specialty}</span>
                    </div>
                  </div>
                </div>
                <div className="nk-divider divider md"></div>
              </div>
            </>
          ))}
        </Block>
      </ModalBody>
    </Modal>
  );
};

export default VoucherDetails;
